/*STYLE IS REFACTORED*/
/*ony for one corner, use this variable several time for other corners*/
.tooltip {
  position: absolute;
  z-index: 1030;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.6;
  opacity: 0;
}
.tooltip.in {
  opacity: 1;
}
.tooltip.top {
  margin-top: -9px;
  padding: 9px 0;
}
.tooltip.right {
  margin-left: 4.5px;
  padding: 0 9px;
}
.tooltip.bottom {
  margin-top: 4.5px;
  padding: 9px 0;
}
.tooltip.left {
  margin-left: -4.5px;
  padding: 0 9px;
}
.tooltip .tooltip-inner {
  padding: 11px 14px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  max-width: 200px;
  text-align: left;
  text-decoration: none;
}
.tooltip.tooltip-small .tooltip-inner {
  padding: 8px 12px;
}
.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -9px;
  border-width: 9px 9px 0;
}
.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  left: 5px;
  border-width: 9px 9px 0;
}
.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  right: 5px;
  border-width: 9px 9px 0;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -9px;
  border-width: 9px 9px 9px 0;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -9px;
  border-width: 9px 0 9px 9px;
}
.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -9px;
  border-width: 0 9px 9px;
}
.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  left: 5px;
  border-width: 0 9px 9px;
}
.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  right: 5px;
  border-width: 0 9px 9px;
}
.tooltip.fade {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.tooltip.fade.in {
  opacity: 1;
}
.pi-section-base .tooltip .tooltip-inner,
.pi-section-white .tooltip .tooltip-inner,
.pi-section-grey .tooltip .tooltip-inner,
.pi-section-dark .tooltip .tooltip-inner,
.pi-section-parallax .tooltip .tooltip-inner {
  background: rgba(46, 52, 60, 0.96);
  color: #c3cdd2;
  text-shadow: none;
  font-weight: 400;
}
.pi-section-base .tooltip.top .tooltip-arrow,
.pi-section-white .tooltip.top .tooltip-arrow,
.pi-section-grey .tooltip.top .tooltip-arrow,
.pi-section-dark .tooltip.top .tooltip-arrow,
.pi-section-parallax .tooltip.top .tooltip-arrow {
  border-top-color: rgba(46, 52, 60, 0.96);
}
.pi-section-base .tooltip.right .tooltip-arrow,
.pi-section-white .tooltip.right .tooltip-arrow,
.pi-section-grey .tooltip.right .tooltip-arrow,
.pi-section-dark .tooltip.right .tooltip-arrow,
.pi-section-parallax .tooltip.right .tooltip-arrow {
  border-right-color: rgba(46, 52, 60, 0.96);
}
.pi-section-base .tooltip.left .tooltip-arrow,
.pi-section-white .tooltip.left .tooltip-arrow,
.pi-section-grey .tooltip.left .tooltip-arrow,
.pi-section-dark .tooltip.left .tooltip-arrow,
.pi-section-parallax .tooltip.left .tooltip-arrow {
  border-left-color: rgba(46, 52, 60, 0.96);
}
.pi-section-base .tooltip.bottom .tooltip-arrow,
.pi-section-white .tooltip.bottom .tooltip-arrow,
.pi-section-grey .tooltip.bottom .tooltip-arrow,
.pi-section-dark .tooltip.bottom .tooltip-arrow,
.pi-section-parallax .tooltip.bottom .tooltip-arrow {
  border-bottom-color: rgba(46, 52, 60, 0.96);
}
.pi-section-base .tooltip.pi-base .tooltip-inner,
.pi-section-white .tooltip.pi-base .tooltip-inner,
.pi-section-grey .tooltip.pi-base .tooltip-inner,
.pi-section-dark .tooltip.pi-base .tooltip-inner,
.pi-section-parallax .tooltip.pi-base .tooltip-inner {
  background: rgba(134, 186, 65, 0.9);
  color: #ffffff;
  text-shadow: none;
  font-weight: 400;
}
.pi-section-base .tooltip.pi-base.top .tooltip-arrow,
.pi-section-white .tooltip.pi-base.top .tooltip-arrow,
.pi-section-grey .tooltip.pi-base.top .tooltip-arrow,
.pi-section-dark .tooltip.pi-base.top .tooltip-arrow,
.pi-section-parallax .tooltip.pi-base.top .tooltip-arrow {
  border-top-color: rgba(134, 186, 65, 0.9);
}
.pi-section-base .tooltip.pi-base.right .tooltip-arrow,
.pi-section-white .tooltip.pi-base.right .tooltip-arrow,
.pi-section-grey .tooltip.pi-base.right .tooltip-arrow,
.pi-section-dark .tooltip.pi-base.right .tooltip-arrow,
.pi-section-parallax .tooltip.pi-base.right .tooltip-arrow {
  border-right-color: rgba(134, 186, 65, 0.9);
}
.pi-section-base .tooltip.pi-base.left .tooltip-arrow,
.pi-section-white .tooltip.pi-base.left .tooltip-arrow,
.pi-section-grey .tooltip.pi-base.left .tooltip-arrow,
.pi-section-dark .tooltip.pi-base.left .tooltip-arrow,
.pi-section-parallax .tooltip.pi-base.left .tooltip-arrow {
  border-left-color: rgba(134, 186, 65, 0.9);
}
.pi-section-base .tooltip.pi-base.bottom .tooltip-arrow,
.pi-section-white .tooltip.pi-base.bottom .tooltip-arrow,
.pi-section-grey .tooltip.pi-base.bottom .tooltip-arrow,
.pi-section-dark .tooltip.pi-base.bottom .tooltip-arrow,
.pi-section-parallax .tooltip.pi-base.bottom .tooltip-arrow {
  border-bottom-color: rgba(134, 186, 65, 0.9);
}

/* ---- BUILT FILE. DO NOT MODIFY THIS DIRECTLY. ---- */

html {
    font-size: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0;
    padding: 0
}

ul, ol, li, dl, dt, dd, h1, h2, h3, h4, h5, h6, p, blockquote, figure, form, fieldset, input, legend, pre, abbr {
    margin: 0;
    padding: 0
}

pre, code, address, caption, th, figcaption {
    font-size: 1em;
    font-weight: normal;
    font-style: normal
}

fieldset, iframe, img {
    border: 0
}

caption, th {
    text-align: left
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

article, aside, footer, header, nav, main, section, summary, details, figure, figcaption {
    display: block
}

audio, canvas, video, progress {
    display: inline-block;
    vertical-align: baseline
}

button {
    font: inherit;
    vertical-align: middle
}

@media print {
    body, #main, #content {
        color: #000
    }

    a, a:link, a:visited {
        color: #000;
        text-decoration: none
    }

    #globalheader,
    #globalfooter,
    #directorynav,
    #tabs,
    .noprint,
    .hide {
        display: none;
    }

    #ac-globalnav,
    #ac-localnav,
    .ctek-menu,
    .xt-header-icons,
    .pi-titlebar,
    .footer-links,
    .footer-contact,
    .langlist
    {
        display: none;
    }

    #footer {
        position:relative!important;
    }
}

html {
    font-size: 112.5%
}

body {
    background-color: #fff;
    color: #333;
    font-style: normal;
    font-family: 'myriad-pro', "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif";
    font-size: 18px;
    line-height: 1.45;
    font-weight: 400
}


body, input, textarea, select, button {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern'
}


a:visited, a:link, a:active {
    text-decoration: none;
	color: #08c;
}

html.no-js a:hover {
    text-decoration: underline
}

html.no-touch a:hover {
    text-decoration: underline
}

h1 {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 200
}


@media only screen and (max-width: 1068px) {
    h1 {
        font-size: 52px;
        line-height: 1.0769;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    h1 {
        font-size: 36px;
        line-height: 1.1111;
        font-weight: 200
    }
}

h2 {
    font-size: 52px;
    line-height: 0.8;
    font-weight: 200
}


@media only screen and (max-width: 1068px) {
    h2 {
        font-size: 42px;
        line-height: 1.1429;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    h2 {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 200
    }
}

h3 {
    font-size: 42px;
    line-height: 1.1429;
    font-weight: 200
}



@media only screen and (max-width: 1068px) {
    h3 {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    h3 {
        font-size: 28px;
        line-height: 1.1429;
        font-weight: 200
    }
}

h4 {
    font-size: 32px;
    line-height: 1.125;
    font-weight: 200
}



@media only screen and (max-width: 1068px) {
    h4 {
        font-size: 28px;
        line-height: 1.1429;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    h4 {
        font-size: 24px;
        line-height: 1.1667;
        font-weight: 200
    }
}

h5 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600
}



h6 {
    font-size: 14px;
    line-height: 1.2857;
    font-weight: 600
}


h1, h2, h3, h4, h5, h6 {
    margin: 0.5em 0 0 0
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin: 0 0 0 0
}

h1 img, h2 img, h3 img, h4 img, h5 img, h6 img {
    display: block;
    margin: 0
}

strong, b {
    font-weight: bold
}

em, i, cite, dfn {
}

p {
    margin: 1em 0 0 0
}

p:first-child {
    margin: 0 0 0 0
}

li li {
    font-size: 1em
}

ul, ol {
    margin: 1em 0 1em 1.1111em
}

ul ul, ul ol, ol ul, ol ol {
    margin-top: 0;
    margin-bottom: 0
}

nav ul, nav ol {
    list-style: none;
    margin: 0
}

sup {
    font-size: .6em;
    opacity: .8;
    vertical-align: top;
    position: relative;
    bottom: -.2em
}

h1 sup, h2 sup, h3 sup {
    font-size: .4em
}

html.oldie h4 sup, html.oldie h5 sup, html.oldie h6 sup {
    font-size: .8em;
    font-weight: normal;
    line-height: 1;
    bottom: auto
}

sup a {
    color: #333
}

sup a:hover {

    text-decoration: none
}

sub {
    line-height: 1
}

abbr {
    border: 0
}

.nowrap {
    white-space: nowrap
}

.clear {
    clear: both
}

#page {
    position:relative;
}

#top {
    position: absolute;
    top: 0
}

.row {
    position: relative;
    z-index: 1
}

.row:before, .row:after {
    content: ' ';
    display: table
}

.row:after {
    clear: both
}

html.oldie .row {
    zoom: 1
}

.column {
    position: relative;
    z-index: 1;
    min-height: 2px;
    margin: 0;
    padding: 0;
    float: left;
    width: 100%
}

.large-1 {
    width: 8.3333%
}

html.oldie .large-1 {
    width: 8.325%
}

.large-2 {
    width: 16.6667%
}

html.oldie .large-2 {
    width: 16.65%
}

.large-3 {
    width: 25%
}

html.oldie .large-3 {
    width: 24.975%
}

.large-4 {
    width: 33.3333%
}

html.oldie .large-4 {
    width: 33.3%
}

.large-5 {
    width: 41.6667%
}

html.oldie .large-5 {
    width: 41.625%
}

.large-6 {
    width: 50%
}

html.oldie .large-6 {
    width: 49.95%
}

.large-7 {
    width: 58.3333%
}

html.oldie .large-7 {
    width: 58.275%
}

.large-8 {
    width: 66.6667%
}

html.oldie .large-8 {
    width: 66.6%
}

.large-10 {
    width: 83.3333%
}

html.oldie .large-10 {
    width: 83.25%
}

.large-auto {
    position: absolute
}

.large-auto-top {
    top: 0
}

.large-auto-bottom {
    bottom: 0
}

.large-auto-left {
    left: 0
}

.large-auto-right {
    right: 0
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .small-1 {
        width: 8.3333%
    }

    .small-offset-1 {
        margin-left: 8.3333%
    }

    .small-pull-1 {
        right: 8.3333%;
        left: auto
    }

    .small-2 {
        width: 16.6667%
    }

    .small-offset-2 {
        margin-left: 16.6667%
    }

    .small-pull-2 {
        right: 16.6667%;
        left: auto
    }

    .small-3 {
        width: 25%
    }

    .small-offset-3 {
        margin-left: 25%
    }

    .small-4 {
        width: 33.3333%
    }

    .small-offset-4 {
        margin-left: 33.3333%
    }

    .small-5 {
        width: 41.6667%
    }

    .small-offset-5 {
        margin-left: 41.6667%
    }

    .small-pull-5 {
        right: 41.6667%;
        left: auto
    }

    .small-6 {
        width: 50%
    }

    .small-7 {
        width: 58.3333%
    }

    .small-8 {
        width: 66.6667%
    }

    .small-offset-8 {
        margin-left: 66.6667%
    }

    .small-9 {
        width: 75%
    }

    .small-offset-9 {
        margin-left: 75%
    }

    .small-pull-9 {
        right: 75%;
        left: auto
    }

    .small-10 {
        width: 83.3333%
    }

    .small-11 {
        width: 91.6667%
    }

    .small-12 {
        width: 100%
    }

    .small-offset-0 {
        margin-left: 0
    }

    .small-pull-0 {
        right: auto;
        left: auto
    }

    .small-auto {
        position: absolute
    }

    .small-auto-top {
        top: 0
    }

    .small-auto-bottom {
        bottom: 0
    }

    .small-auto-left {
        left: 0
    }

    .small-auto-right {
        right: 0
    }
}

@media only screen and (min-device-width: 768px) {
    body {
        min-width: 768px
    }
}

@media only screen and (max-device-width: 767px) {
    html.touch body {
        min-width: 0
    }

}

.dotnav ul {
    display: inline-block
}

html.oldie .dotnav ul {
    display: inline;
    zoom: 1
}

.dotnav li {
    list-style: none;
    margin: 0 10px;
    width: 8px;
    height: 8px;
    float: left;
    position: relative
}

.icon, .more, .breadcrumbs-list > li {
}

.icon:before, .more:before, .breadcrumbs-list > li:before {
    display: none;
    padding-right: .3em
}

.icon:after, .more:after, .breadcrumbs-list > li:after {
    display: inline-block;
    padding-left: .3em
}

.icon-before:before, .breadcrumbs-list > li:before {
    display: inline-block;
    position: relative;
    top: -.125em
}

.icon-before:after, .breadcrumbs-list > li:after {
    display: none
}

.icon-apple:before, .icon-apple:after {
    content: ""
}

.icon-more:before, .icon-more:after {
    content: ""
}

.icon-info:before, .icon-info:after {
    content: ""
}

.icon-list:before, .icon-list:after {
    content: ""
}

.icon-grid:before, .icon-grid:after {
    content: ""
}

.breadcrumbs-list > li:before, .breadcrumbs-list > li:after {
    content: '\f017'
}

.more:before, .more:after {
    content: '\f004'
}

.icon-apple.icon-before:before {
    top: 0
}

.more-block {
    margin-top: .5em
}

a.block {
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: inherit
}

html.oldie a.block {

}

a.block:before, a.block:after {
    content: ' ';
    display: table
}

a.block:after {
    clear: both
}

html.oldie a.block {
    zoom: 1
}

html.no-js a.block:hover {
    text-decoration: none
}

html.no-js a.block:hover .block-link {
    text-decoration: underline
}

html.no-touch a.block:hover {
    text-decoration: none
}

html.no-touch a.block:hover .block-link {
    text-decoration: underline
}

a.block .block-link {
    color: #08c;
    cursor: pointer
}

a.block-inline {
    display: inline-block
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
}

.footer-global {
    max-width: 980px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    color: #999;
    padding-top: 18px;
    padding-bottom: 18px;
    line-height: 1;
    font-size: 1em
}

.footer-global:before, .footer-global:after {
    content: ' ';
    display: table
}

.footer-global:after {
    clear: both
}

html.oldie .footer-global {
    zoom: 1
}

.footer-global a {
    color: #666
}

html.no-js .footer-global a:hover {
    color: #333
}

html.no-touch .footer-global a:hover {
    color: #333
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .footer-global {
        width: auto;
        padding-left: 5%;
        padding-right: 5%
    }
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
}

.breadcrumbs {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    direction: rtl
}

html.oldie .breadcrumbs {
    overflow: visible
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) and (orientation: portrait) {
    .breadcrumbs {
        display: inline-block
    }
}

.breadcrumbs-list > li:first-child {
    margin-left: 12px
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .breadcrumbs-list > li:first-child {
        margin-left: 32px
    }

    .breadcrumbs-list > li:first-child:before {
        display: none
    }
}

.breadcrumbs-list > li {
    display: inline-block;
    padding-left: .8em;
    white-space: nowrap;
    font-size: 12px;
    line-height: 2;
    font-weight: 400
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .breadcrumbs-list > li {
        font-size: 14px;
        line-height: 2;
        font-weight: 600
    }
}

html.oldie .breadcrumbs-list > li {
    display: block;
    float: left;
    margin-left: 14px;
    margin-top: 2px
}

.breadcrumbs-list > li a {
    color: #666
}

html.no-js .breadcrumbs-list > li a:hover {
    color: #333;
    text-decoration: none
}

html.no-touch .breadcrumbs-list > li a:hover {
    color: #333;
    text-decoration: none
}

.breadcrumbs-list > li:before {
    font-size: 1.3333em;
    line-height: 1;
    padding-right: .5em;
    top: .1em;
    color: #d6d6d6;
    font-weight: 400
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .breadcrumbs-list > li {
        padding-left: .2em
    }
}

.breadcrumbs-home {
    color: #666;
    background: #f2f2f2;
    display: block;
    font-size: 1.1111em;
    line-height: 1;
    height: 1em;
    position: absolute;
    top: 0.2222em;
    left: 0;
    z-index: 2;
    font-family: 'myriad-pro', 'Myriad Pro'
}

html.oldie .breadcrumbs-home {
    line-height: .9
}

html.no-js .breadcrumbs-home:hover {
    color: #333;
    text-decoration: none
}

html.no-touch .breadcrumbs-home:hover {
    color: #333;
    text-decoration: none
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .breadcrumbs-home {
        border-right: 1px solid #e3e3e3;
        padding-right: .5em
    }
}

.directorynav > .column h3 {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 1.4167;
    font-weight: 600;
    color: #333;
    letter-spacing: 0
}

html.oldie .directorynav > .column h3 {
    font-weight: normal;
    font-family: "myriad-pro", "myriad pro", "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif"
}



@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .directorynav > .column h3 {
        font-size: 14px;
        line-height: 2.2857;
        font-weight: 400;
        border-top: 1px solid #e3e3e3;
        position: relative;
        padding: 0.2222em 0.3333em
    }

    .directorynav > .column h3:after {
        content: '+';
        float: right;
        padding: 0.45em 0.4444em;
        font-size: 1.1111em;
        font-weight: 200;
        line-height: 1;
        -webkit-transform: rotate(0deg) scale(0.9);
        -ms-transform: rotate(0deg) scale(0.9);
        transform: rotate(0deg) scale(0.9);
        -webkit-transition: -webkit-transform .3s ease-out;
        transition: transform .3s ease-out;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center
    }
}

.directorynav > .column label {
    -webkit-tap-highlight-color: transparent
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .directorynav > .column ul {
        text-indent: 1em;
        padding-bottom: 0;
        max-height: 0;
        overflow: hidden;
        padding: 0
    }
}

.directorynav > .column li {
    line-height: 1
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .directorynav > .column li a {
        position: relative;
        padding: 0.2222em 0.3333em
    }

    .directorynav > .column li {
        -webkit-transform: translateY(-120px);
        -ms-transform: translateY(-120px);
        transform: translateY(-120px);
        opacity: 0;
        -webkit-transition: opacity .3s ease-in, -webkit-transform .3s ease-out;
        transition: opacity .3s ease-in, transform .3s ease-out
    }
}

.directorynav > .column input {
    display: none
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .directorynav > .column input:checked ~ ul {
        max-height: 100%
    }

    .directorynav > .column input:checked ~ ul li {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0)
    }

    .directorynav > .column input:checked + label h3 {
        font-weight: 600
    }

    .directorynav > .column input:checked + label h3:before {
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        content: '';
        height: 16px;
        z-index: 1;
        background: -webkit-linear-gradient(#f2f2f2 30%, rgba(242, 242, 242, 0) 80%);
        background: linear-gradient(#f2f2f2 30%, rgba(242, 242, 242, 0) 80%)
    }

    .directorynav > .column input:checked + label h3:after {
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1)
    }
}

.directorynav > .column a {
    font-size: 12px;
    line-height: 1.4167;
    font-weight: 400;
    display: inline;
    color: #666;
    white-space: normal
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .directorynav > .column a {
        font-size: 14px;
        line-height: 2.2857;
        font-weight: 400
    }
}

html.no-js .directorynav > .column a:hover {
    color: #333
}

html.no-touch .directorynav > .column a:hover {
    color: #333
}

.footer-links > li {
    display: inline-block;
    margin-left: 0
}

html.oldie .footer-links > li {
    display: inline;
    zoom: 1
}

.footer-links > li > a {
    padding: 0 0.4444em 0 0;
    margin-right: 0.3333em;
    border-right: 1px solid #e3e3e3;
    white-space: nowrap
}

.footer-links > li > a.choose > img {
    position: absolute;
    left: -1px;
    top: -2px
}

html.oldie .footer-links > li > a.choose > img {
    line-height: .8em
}

@media only screen and (min-width: 1442px) {
}

.footer-sosumi > p {
    margin-right: 1.33333em
}

.footer-sosumi > p, .footer-sosumi > ul, .footer-sosumi > ul > li {
    display: inline;
    margin-left: 0;
    margin-top: 0
}

.footer-sosumi > p > a, .footer-sosumi > ul > a, .footer-sosumi > ul > li > a {
    padding: 0 0.5em 0 0;
    margin-right: 0.3333em;
    border-right: 1px solid #e3e3e3;
    white-space: nowrap
}

.footer-sosumi > p li:last-child > a, .footer-sosumi > ul li:last-child > a, .footer-sosumi > ul > li li:last-child > a {
    border-right: 0
}

@media only screen and (max-width: 28em) and (max-device-width: 735px) {
    .footer-shop span {
        display: block
    }
}

.footer-white {
    background: #fff
}

.footer-white .footer-global {
    color: #999
}

.footer-white .footer-global a {
    color: #666
}

html.no-js .footer-white .footer-global a:hover {
    color: #333
}

html.no-touch .footer-white .footer-global a:hover {
    color: #333
}

.footer-white .breadcrumbs {
    color: #666
}

.footer-white .breadcrumbs > li a {
    color: #666
}

html.no-js .footer-white .breadcrumbs > li a:hover {
    color: #333
}

html.no-touch .footer-white .breadcrumbs > li a:hover {
    color: #333
}

.footer-white .breadcrumbs > li:before {
    color: #e3e3e3
}

.footer-white .directorynav a {
    color: #666
}

html.no-js .footer-white .directorynav a:hover {
    color: #333
}

html.no-touch .footer-white .directorynav a:hover {
    color: #333
}

.footer-white .directorynav h3 {
    color: #333
}

.footer-white .footer-links > li > a, .footer-white .footer-sosumi > p > a, .footer-white .footer-sosumi > ul > a, .footer-white .footer-sosumi > ul > li > a {
    border-color: #e3e3e3
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .footer-white .directorynav > .column {
        border-color: #e3e3e3
    }

    .footer-white .directorynav > .column input:checked + label h3:before {
        background: -webkit-linear-gradient(#fff 30%, rgba(255, 255, 255, 0) 80%);
        background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0) 80%)
    }
}

.footer-white .breadcrumbs-home {
    background: #fff
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
    .footer-white .breadcrumbs-home:after {
        background-image: -webkit-linear-gradient(left, #fff 10%, rgba(255, 255, 255, 0));
        background-image: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0))
    }
}

.sosumi a {
    color: #666
}

html.no-js .sosumi a:hover {
    text-decoration: underline
}

html.no-touch .sosumi a:hover {
    text-decoration: underline
}

.sosumi ol, .sosumi ul {
    margin: 0;
    padding-right: 16px;
    padding-left: 16px;
    list-style-position: outside
}

.sosumi ul {
    list-style: none;
    margin-right: -16px;
    margin-left: -16px
}

.sosumi li {
    padding-bottom: 5px
}

.sosumi p, .sosumi li {
    font-size: 12px;
    line-height: 1.3333;
    font-weight: 400
}

.sosumi small {
    font-size: 1em
}

@media only screen and (max-width: 735px) and (max-device-width: 768px) {
}

html.oldie .footer-wrapper .sosumi ul {
    padding-right: 0;
    padding-left: 0
}


.page-home .gallery-content .copy {
    position: relative;
    z-index: 3
}

.page-home .gallery-content h1 {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 200;
    letter-spacing: -0.015em;
    margin-top: 0
}

html.oldie .page-home .gallery-content h1 {
    font-weight: normal;
    font-family: "myriad-pro", "myriad pro", "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif"
}



@media only screen and (min-width: 1801px) {
    .page-home .gallery-content h1 {
        font-size: 64px;
        line-height: 1.0625;
        font-weight: 200
    }
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    .page-home .gallery-content h1 {
        font-size: 52px;
        line-height: 1.0769;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) {
    .page-home .gallery-content h1 {
        font-size: 36px;
        line-height: 1.1111;
        font-weight: 200
    }
}

.page-home .gallery-content h2 {
    margin-top: 0em;
    margin-bottom: 0.8572em;
    font-size: 42px;
    line-height: 1.1429;
    font-weight: 200;
    letter-spacing: -0.025em
}

html.oldie .page-home .gallery-content h2 {
    font-weight: normal;
    font-family: "myriad-pro", "myriad pro", "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif"
}


@media only screen and (min-width: 1801px) {
    .page-home .gallery-content h2 {
        font-size: 42px;
        line-height: 1.1429;
        font-weight: 200
    }
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    .page-home .gallery-content h2 {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) {
    .page-home .gallery-content h2 {
        font-size: 28px;
        line-height: 1.1429;
        font-weight: 200;
        letter-spacing: -0.015em
    }
}

.page-home .gallery-content .links {
    margin: 0.75em 0 0;
    list-style: none;
    font-size: 24px;
    line-height: 1.3333;
    font-weight: 200;
    color: #08c
}

html.oldie .page-home .gallery-content .links {
    font-weight: normal;
    font-family: "myriad-pro", "myriad pro", "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif"
}



@media only screen and (min-width: 1801px) {
    .page-home .gallery-content .links {
        font-size: 24px;
        line-height: 1.3333;
        font-weight: 200
    }
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    .page-home .gallery-content .links {
        font-size: 20px;
        line-height: 1.4;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) {
    .page-home .gallery-content .links {
        font-size: 14px;
        line-height: 1.2857;
        font-weight: 400
    }
}

.page-home .gallery-content .links a, .page-home .gallery-content .links .block-link {
    white-space: nowrap;
    text-decoration: none;
    pointer-events: all;
    display: inline-block
}

.page-home .gallery-content .links a:hover, .page-home .gallery-content .links .block-link:hover {
    color: #000
}

html.touch .page-home .gallery-content .links a:hover, html.touch .page-home .gallery-content .links .block-link:hover {
    color: #08c
}

.page-home .gallery-content .links li {
    display: inline-block;
    margin-left: 0.3em;
    margin-right: 0.35em;
    margin-bottom: .5em
}

html.oldie .page-home .gallery-content .links li {
    display: inline;
    zoom: 1
}

.page-home .gallery-content.dark .links a, .page-home .gallery-content.dark .links span, .page-home .gallery-content.dark .links .block-link {
    color: #5cc3f6
}

.page-home .gallery-content.dark .links a:hover, .page-home .gallery-content.dark .links span:hover, .page-home .gallery-content.dark .links .block-link:hover {
    color: #fff
}

html.touch .page-home .gallery-content.dark .links a:hover, html.touch .page-home .gallery-content.dark .links span:hover, html.touch .page-home .gallery-content.dark .links .block-link:hover {
    color: #5cc3f6
}

.hero-slides .legend {
    position: absolute;
    z-index: 9999;
    top: 50px;
    left: 50px;
    width: 400px;
    display: block;
    color: #555
}

.hero-slides .legend span, .hero-slides .legend * {
    color: #555
}

.hero-slides .legend span::after {
    content: "large"
}

@media only screen and (min-width: 1801px) {
    .hero-slides .legend span::after {
        content: "xlarge"
    }
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    .hero-slides .legend span::after {
        content: "medium"
    }
}

@media only screen and (max-width: 735px) {
    .hero-slides .legend span::after {
        content: "small"
    }
}

.hero-slides h1, .hero-slides h2, .hero-slides h3, .hero-slides h4, .hero-slides h5, .hero-slides h6, .hero-slides p, .hero-slides li {
    color: #333
}

.hero-slides .dark h1, .hero-slides .dark h2, .hero-slides .dark h3, .hero-slides .dark h4, .hero-slides .dark h5, .hero-slides .dark h6, .hero-slides .dark p, .hero-slides .dark li {
    color: #fff
}

.hero-slides .hero .copy {
    position: relative;
    z-index: 3
}

.hero-slides .hero h1 {
    font-size: 64px;
    line-height: 1.0625;
    font-weight: 200;
    letter-spacing: -0.015em
}

html.oldie .hero-slides .hero h1 {
    font-weight: normal;
    font-family: "myriad-pro", "myriad pro", "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif"
}



@media only screen and (min-width: 1801px) {
    .hero-slides .hero h1 {
        font-size: 64px;
        line-height: 1.0625;
        font-weight: 200
    }
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    .hero-slides .hero h1 {
        font-size: 52px;
        line-height: 1.0769;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) {
    .hero-slides .hero h1 {
        font-size: 36px;
        line-height: 1.1111;
        font-weight: 200
    }
}

.hero-slides .hero h1 sup {
    bottom: initial;
    vertical-align: super
}

.hero-slides .hero h2 {
    margin-top: 0em;
    margin-bottom: 0.8em;
    font-size: 42px;
    line-height: 1.1429;
    font-weight: 200;
    letter-spacing: -0.025em
}

html.oldie .hero-slides .hero h2 {
    font-weight: normal;
    font-family: "myriad-pro", "myriad pro", "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif"
}



@media only screen and (min-width: 1801px) {
    .hero-slides .hero h2 {
        font-size: 42px;
        line-height: 1.1429;
        font-weight: 200
    }
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    .hero-slides .hero h2 {
        font-size: 32px;
        line-height: 1.125;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) {
    .hero-slides .hero h2 {
        font-size: 28px;
        line-height: 1.1429;
        font-weight: 200;
        letter-spacing: -0.015em
    }
}

.hero-slides .hero .links {
    margin: 0.75em 0 0;
    list-style: none;
    font-size: 24px;
    line-height: 1.3333;
    font-weight: 200;
    color: #08c
}

html.oldie .hero-slides .hero .links {
    font-weight: normal;
    font-family: "myriad-pro", "myriad pro", "Lucida Grande", "Helvetica Neue", "Segoe UI", "Arial", "Verdana", "sans-serif"
}



@media only screen and (min-width: 1801px) {
    .hero-slides .hero .links {
        font-size: 24px;
        line-height: 1.3333;
        font-weight: 200
    }
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    .hero-slides .hero .links {
        font-size: 20px;
        line-height: 1.4;
        font-weight: 200
    }
}

@media only screen and (max-width: 735px) {
    .hero-slides .hero .links {
        font-size: 14px;
        line-height: 1.2857;
        font-weight: 400
    }
}

.hero-slides .hero .links a, .hero-slides .hero .links span, .hero-slides .hero .links .block-link {
    white-space: nowrap;
    text-decoration: none;
    pointer-events: all;
    color: #08c
}

.hero-slides .hero .links a:hover, .hero-slides .hero .links span:hover, .hero-slides .hero .links .block-link:hover {
    color: #000
}

html.touch .hero-slides .hero .links a:hover, html.touch .hero-slides .hero .links span:hover, html.touch .hero-slides .hero .links .block-link:hover {
    color: #08c
}

.hero-slides .hero .links li {
    display: inline-block;
    margin-left: 0.35em;
    margin-right: 0.35em
}

html.oldie .hero-slides .hero .links li {
    display: inline;
    zoom: 1
}

.hero-slides .hero.dark .links a, .hero-slides .hero.dark .links .block-link {
    color: #5cc3f6
}

.hero-slides .hero.dark .links a:hover, .hero-slides .hero.dark .links .block-link:hover {
    color: #fff
}

html.touch .hero-slides .hero.dark .links a:hover, html.touch .hero-slides .hero.dark .links .block-link:hover {
    color: #5cc3f6
}

@media only screen and (max-width: 735px) {
    .hero-slides .hero .links {
        margin-top: 0.40em
    }
}

.hero-slides .hero-content .copy {
    position: relative;
    z-index: 4 !important;
    pointer-events: none
}

.hero-slides .hero-content .copy a {
    pointer-events: all
}

.promos ul {
    margin: 0;
    position: relative;
    z-index: 1
}

.promos ul:before, .promos ul:after {
    content: ' ';
    display: table
}

.promos ul:after {
    clear: both
}

html.oldie .promos ul {
    zoom: 1
}

.promos li {
    list-style: none;
    position: relative;
    z-index: 1;
    min-height: 2px;
    margin: 0;
    padding: 0;
    float: left;
    width: 25%
}

html.oldie .promos li {
    width: 24.975%
}

@media only screen and (max-width: 1068px) {
    .promos li {
        position: relative;
        z-index: 1;
        min-height: 2px;
        margin: 0;
        padding: 0;
        float: left;
        width: 50%
    }
}

@media only screen and (max-width: 735px) {
    .promos li {
        position: relative;
        z-index: 1;
        min-height: 2px;
        margin: 0;
        padding: 0;
        float: left;
        width: 100%
    }
}

html {
    width: 100%;
    height: 100%
}

@media only screen and (max-width: 735px) {
    html {
        min-width: 300px
    }

}

@media only screen and (max-width: 735px) and (orientation: landscape) {
    html {
        min-width: 460px
    }

}

@media only screen and (max-width: 735px) {
    html.touch {
        min-width: 320px
    }

}

body {
    width: 100%;
    height: 100%;
    background-color: #fff
}

html.oldie body {
    min-width: 1024px
}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    body {
        min-width: 85%
    }
}

@media only screen and (max-width: 735px) {
    .footer-shop span {
        display: block
    }
}

.hero-slides .copy .copy-inner h2 {
    margin-bottom: 0.25em
}

.hero-slides .copy .copy-inner .links {
    margin-top: 0
}

.hero-slides.evergreen  .copy-inner h1 {
    margin-bottom: 8px
}

@media only screen and (max-width: 1550px) {
    .hero-slides.evergreen  .copy-inner h1 {
        font-size: 56px
    }
}

@media only screen and (max-width: 1068px) {
    .hero-slides.evergreen  .copy-inner h1 {
        font-size: 52px
    }
}

@media only screen and (max-width: 735px) {
    .hero-slides.evergreen  .copy-inner h1 {
        font-size: 36px;
        margin-bottom: 10px
    }
}

@media only screen and (max-width: 1550px) {
    .hero-slides.evergreen  .copy-inner .links {
        font-size: 24px
    }
}

@media only screen and (max-width: 735px) {
    .hero-slides.evergreen  .copy-inner .links {
        font-size: 18px
    }
}

.hero-slides.takeover  .copy .copy-inner h2.product-text {
    margin-bottom: 0.5em
}

@media only screen and (max-width: 735px) {
    .hero-slides.takeover  .copy .copy-inner h2.product-text {
        margin-bottom: 0.25em
    }
}

.hero-slides.takeover  .hero-content .copy {
    top: 0;
    bottom: auto;
    left: 0;
    right: auto;
    overflow: visible;
    height: 100%;
    width: 100%
}

.hero-slides.takeover  .hero-content .copy:before, .hero-slides.takeover  .hero-content .copy:after {
    content: ' ';
    display: inline-block;
    height: 100%;
    width: 0px;
    font-size: 0;
    margin: 0 -1000px;
    padding: 0;
    vertical-align: middle
}

.hero-slides.takeover  .hero-content .copy {
    left: 50%;
    top: auto;
    vertical-align: bottom;
    height: 40%
}

.hero-slides.takeover  .hero-content .copy-inner * {
    text-align: center
}

html.oldie .hero-slides.takeover  .hero-content .copy {
    top: 0;
    height: 100%;
    vertical-align: top
}

.hero-slides.takeover  .hero-content .copy {
    min-height: 470px
}

html.oldie .hero-slides.takeover  .hero-content .copy {
    top: 120px
}

@media only screen and (min-width: 1801px) {
    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;
        overflow: visible;
        height: 100%;
        width: 100%
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy:before, html.no-oldie .hero-slides.takeover  .hero-content .copy:after {
        content: ' ';
        display: inline-block;
        height: 100%;
        width: 0px;
        font-size: 0;
        margin: 0 -1000px;
        padding: 0;
        vertical-align: middle
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        left: 50%;
        top: auto;
        vertical-align: bottom;
        height: 40%
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy-inner * {
        text-align: center
    }

    html.no-oldie.oldie .hero-slides.takeover  .hero-content .copy {
        top: 0;
        height: 100%;
        vertical-align: top
    }

}

@media only screen and (min-width: 1801px) {
    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        min-height: 470px
    }

}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;
        overflow: visible;
        height: 100%;
        width: 100%
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy:before, html.no-oldie .hero-slides.takeover  .hero-content .copy:after {
        content: ' ';
        display: inline-block;
        height: 100%;
        width: 0px;
        font-size: 0;
        margin: 0 -1000px;
        padding: 0;
        vertical-align: middle
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        left: 50%;
        top: auto;
        vertical-align: bottom;
        height: 40%
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy-inner * {
        text-align: center
    }

    html.no-oldie.oldie .hero-slides.takeover  .hero-content .copy {
        top: 0;
        height: 100%;
        vertical-align: top
    }

}

@media only screen and (max-width: 1068px), only screen and (max-height: 890px) {
    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        min-height: 345px
    }

    html.no-oldie .hero-slides.takeover  .links li {
        margin-bottom: 0.25em
    }

}

@media only screen and (max-width: 735px) and (orientation: portrait) {
    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        top: 0;
        bottom: auto;
        left: 0;
        right: auto;
        overflow: visible;
        height: 100%;
        width: 100%
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy:before, html.no-oldie .hero-slides.takeover  .hero-content .copy:after {
        content: ' ';
        display: inline-block;
        height: 100%;
        width: 0px;
        font-size: 0;
        margin: 0 -1000px;
        padding: 0;
        vertical-align: middle
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy {
        left: 50%;
        top: auto;
        vertical-align: bottom;
        height: 46%
    }

    html.no-oldie .hero-slides.takeover  .hero-content .copy-inner * {
        text-align: center
    }

    html.no-oldie.oldie .hero-slides.takeover  .hero-content .copy {
        top: 0;
        height: 100%;
        vertical-align: top
    }

}

@media only screen and (max-width: 735px) and (orientation: portrait) and (min-width: 1801px) and (-webkit-min-device-pixel-ratio: 1.5), only screen and (max-width: 735px) and (orientation: portrait) and (min-width: 1801px) and (min-resolution: 144dpi), only screen and (max-width: 735px) and (orientation: portrait) and (min-width: 1801px) and (min-resolution: 144dppx) {
}

.contactFormInput
{
    background:White!important;
    color:#006699!important;
}

.contactFormTextArea
{
    width:97%!important;
}


.h1-page-header{
    font-size:57px !important;
    font-weight:lighter !important;
}

.h1-section-gray {
    color: #777777 !important;
    font-size: 30px !important;
    line-height:35px !important;
}

.testimonial-item {
    margin: 15px 0 20px;
    border-bottom: 1px dashed #d6dfdf;
}


.cookie_wrapper {
    display: none;
    border: 0;
    padding: 0;
    background: #404040;
    color: #fff;
    height: auto;
}

.cookie_wrapper .cookie_policy_content {
    position: relative;
    max-width: 1048px;
    margin: 0 auto;
    padding: 20px 60px 20px 32px;
    background: url("../images/icons/info-16x16.png") no-repeat 4px 20px;
    font-size: 13px;
    line-height: 16px;

}

.cookie_wrapper a, .cookie_wrapper a:visited, .cookie_wrapper a:hover {
    color: #fff;
    text-decoration: underline!important;
}

.cookie_wrapper .cookie_policy_content a#close-cookie-policy {
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    text-indent: -9999px;
    width: 31px;
    height: 31px;
    background: url("../images/icons/x-23x23.png") no-repeat center;
}

.capEmailTcha {
    display: none;
}

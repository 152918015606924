

/*******************************************************************************
		-	BULLETS AND ARROWS ADD ONS TO THE EXISTING VERSION 	-
*******************************************************************************/

.tp-bannertimer {
    background:#777 !important;
    background:rgba(0,0,0,0.1) !important;
    height:5px !important;
  }

.tp-bullets.simplebullets .bullet {
    cursor: pointer;
    position: relative !important;
    background: rgba(0, 0, 0, 0.5) !important;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 6px !important;
    height: 6px !important;
    border: 5px solid rgba(0, 0, 0, 0) !important;
    display: inline-block;
    margin-right: 2px !important;
    margin-bottom: 14px !important;
    -webkit-transition: background-color 0.2s, border-color 0.2s;
    -moz-transition: background-color 0.2s, border-color 0.2s;
    -o-transition: background-color 0.2s, border-color 0.2s;
    -ms-transition: background-color 0.2s, border-color 0.2s;
    transition: background-color 0.2s, border-color 0.2s;
    float:none !important;
}
.tp-bullets.simplebullets .bullet.last {
    margin-right: 0px
}
.tp-bullets.simplebullets .bullet:hover,
.tp-bullets.simplebullets .bullet.selected {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
	background: rgba(255, 255, 255, 1) !important;
	width: 6px !important;
    height: 6px !important;
    border: 5px solid rgba(0, 0, 0, 1) !important;
}



.tparrows:before {
    font-family: 'revicons';
	color: #fff;
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    margin-right: 0;
    margin-top: 9px;
    text-align: center;
    width: 40px;
    font-size: 20px;
}
.tparrows {

    cursor: pointer;

    background: rgba(0, 0, 0, 0.5) !important;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 40px !important;
    height: 40px !important;

}
.tparrows:hover {
    color: #fff;
}
.tp-leftarrow:before {
    content: '\e824';
}
.tp-rightarrow:before {
    content: '\e825';
}
.tparrows.tp-rightarrow:before {
    margin-left: 1px;
}
.tparrows:hover {
    background: rgba(0, 0, 0, 1) !important;
}



/******************************
	-	SLIDER NAV STYLE DEMOS	-
********************************/

#slider4container .tparrows:before,
#slider4container .tparrows:hover,
#slider4container .tparrows {
    color: #000 !important;
}

#slider4container .tparrows {
	background:#fff !important;
	background:rgba(255,255,255,0.5) !important;
}

#slider4container .tparrows:hover	{
	background:#fff !important
}
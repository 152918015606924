.pi-loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background: none;
}
.pi-loader > span {
  display: table;
  width: 100%;
  height: 100%;
}
.pi-loader > span > span {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  line-height: 10px;
}
.pi-loader .pi-loader-dot {
  display: inline-block;
  width: 11px;
  height: 11px;
  margin: 0 4px;
  vertical-align: top;
  background: rgba(105, 116, 122, 0.25);
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.pi-loader-dot.pi-loader-dot-active {
  background: #006699;
}

html {
	font-size: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%
}
body {
	margin: 0;
	padding: 0
}
ul, ol, li, dl, dt, dd, h1, h2, h3, h4, h5, h6, hgroup, p, blockquote, figure, form, fieldset, input, legend, pre, abbr {
	margin: 0;
	padding: 0
}
pre, code, address, caption, th, figcaption {
	font-size: 1em;
	font-weight: normal;
	font-style: normal
}
fieldset, iframe, img {
	border: 0
}
caption, th {
	text-align: left
}
table {
	border-collapse: collapse;
	border-spacing: 0
}
article, aside, footer, header, nav, main, section, summary, details, hgroup, figure, figcaption {
	display: block
}
audio, canvas, video, progress {
	display: inline-block;
	vertical-align: baseline
}
button {
	border: 0;
	font: inherit;
	vertical-align: middle
}

@media print {
body, #main, #content {
	color: #000
}
a, a:link, a:visited {
	color: #000;
	text-decoration: none
}
#globalheader, #globalfooter, #directorynav, #tabs, .noprint, .hide {
	display: none
}
}
html {
	font-family: "myriad-pro", "myriad pro", "Helvetica Neue", "Segoe UI", "Arial", sans-serif;
	font-size: 112.5%
}
body {
	font-size: 18px;
	line-height: 1.45;
	font-weight: 400;
	letter-spacing: 0.1px;
	background-color: #fff;
	color: #333;
	font-style: normal
}

@media only screen and (max-width: 1068px) {
body {
	font-size: 18px;
	line-height: 1.45;
	font-weight: 400;
	letter-spacing: normal
}
}

@media only screen and (max-width: 735px) {
body {
	font-size: 18px;
	line-height: 1.45;
	font-weight: 400;
	letter-spacing: normal
}
}
body, input, textarea, select, button {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: 'liga', 'kern'
}

a:visited, a:link, a:active {
	text-decoration: none
}
html.no-js a:hover {
	text-decoration: underline
}
html.no-touch a:hover {
	text-decoration: underline
}
h1, h2, h3, h4, h5, h6 {
	margin: 0 0 0.45em 0
}
* + h1, * + h2, * + h3, * + h4, * + h5, * + h6 {
	margin-top: 1em
}
h1 img, h2 img, h3 img, h4 img, h5 img, h6 img {
	display: block;
	margin: 0
}
strong, b {
	font-weight: bold
}
em, i, cite, dfn {
}
p {
	margin: 0 0 1em 0
}
li li {
	font-size: 1em
}
ul, ol {
	margin: 1em 0 1em 1.1111em
}
ul ul, ul ol, ol ul, ol ol {
	margin-top: 0;
	margin-bottom: 0
}
nav ul, nav ol {
	list-style: none;
	margin: 0
}
sup {
	font-size: .6em;
	opacity: .8;
	vertical-align: top;
	position: relative;
	bottom: -.2em
}
h1 sup, h2 sup, h3 sup {
	font-size: .4em
}
html.oldie h4 sup, html.oldie h5 sup, html.oldie h6 sup {
	font-size: .8em;
	font-weight: normal;
	line-height: 1;
	bottom: auto
}
sup a {
	color: #333
}
sup a:hover {

	text-decoration: none
}
sub {
	line-height: 1
}
abbr {
	border: 0
}
.selfclear:before, .selfclear:after {
	content: ' ';
	display: table
}
.selfclear:after {
	clear: both
}
html.oldie .selfclear {
	zoom: 1
}
.visuallyhidden {
	position: absolute;
	top: 0;
	left: 0;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 99.9% 99.9%);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0
}
.imagereplace {
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap
}
.nowrap {
	white-space: nowrap
}
.clear {
	clear: both
}
#top {
	position: absolute;
	top: 0
}
.cursor-grab {
	cursor: grab;
}
.cursor-grabbing {
	cursor: grabbing
}
.row {
	position: relative;
	z-index: 1
}
.row:before, .row:after {
	content: ' ';
	display: table
}
.row:after {
	clear: both
}
html.oldie .row {
	zoom: 1
}
.column {
	position: relative;
	z-index: 1;
	min-height: 2px;
	margin: 0;
	padding: 0;
	float: left
}
.large-offset-0 {
	margin-left: 0
}
.large-push-0 {
	left: auto;
	right: auto
}
.large-pull-0 {
	right: auto;
	left: auto
}
.large-1 {
	width: 8.3333%
}
html.oldie .large-1 {
	width: 8.325%
}
.large-offset-1 {
	margin-left: 8.3333%
}
html.oldie .large-offset-1 {
	margin-left: 8.325%
}
.large-push-1 {
	left: 8.3333%;
	right: auto
}
.large-pull-1 {
	right: 8.3333%;
	left: auto
}
.large-2 {
	width: 16.6667%
}
html.oldie .large-2 {
	width: 16.65%
}
.large-offset-2 {
	margin-left: 16.6667%
}
html.oldie .large-offset-2 {
	margin-left: 16.65%
}
.large-push-2 {
	left: 16.6667%;
	right: auto
}
.large-pull-2 {
	right: 16.6667%;
	left: auto
}
.large-3 {
	width: 25%
}
html.oldie .large-3 {
	width: 24.975%
}
.large-offset-3 {
	margin-left: 25%
}
html.oldie .large-offset-3 {
	margin-left: 24.975%
}
.large-push-3 {
	left: 25%;
	right: auto
}
.large-pull-3 {
	right: 25%;
	left: auto
}
.large-4 {
	width: 33.3333%
}
html.oldie .large-4 {
	width: 33.3%
}
.large-offset-4 {
	margin-left: 33.3333%
}
html.oldie .large-offset-4 {
	margin-left: 33.3%
}
.large-push-4 {
	left: 33.3333%;
	right: auto
}
.large-pull-4 {
	right: 33.3333%;
	left: auto
}
.large-5 {
	width: 41.6667%
}
html.oldie .large-5 {
	width: 41.625%
}
.large-offset-5 {
	margin-left: 41.6667%
}
html.oldie .large-offset-5 {
	margin-left: 41.625%
}
.large-push-5 {
	left: 41.6667%;
	right: auto
}
.large-pull-5 {
	right: 41.6667%;
	left: auto
}
.large-6 {
	width: 50%
}
html.oldie .large-6 {
	width: 49.95%
}
.large-offset-6 {
	margin-left: 50%
}
html.oldie .large-offset-6 {
	margin-left: 49.95%
}
.large-push-6 {
	left: 50%;
	right: auto
}
.large-pull-6 {
	right: 50%;
	left: auto
}
.large-7 {
	width: 58.3333%
}
html.oldie .large-7 {
	width: 58.275%
}
.large-offset-7 {
	margin-left: 58.3333%
}
html.oldie .large-offset-7 {
	margin-left: 58.275%
}
.large-push-7 {
	left: 58.3333%;
	right: auto
}
.large-pull-7 {
	right: 58.3333%;
	left: auto
}
.large-8 {
	width: 66.6667%
}
html.oldie .large-8 {
	width: 66.6%
}
.large-offset-8 {
	margin-left: 66.6667%
}
html.oldie .large-offset-8 {
	margin-left: 66.6%
}
.large-push-8 {
	left: 66.6667%;
	right: auto
}
.large-pull-8 {
	right: 66.6667%;
	left: auto
}
.large-9 {
	width: 75%
}
html.oldie .large-9 {
	width: 74.925%
}
.large-offset-9 {
	margin-left: 75%
}
html.oldie .large-offset-9 {
	margin-left: 74.925%
}
.large-push-9 {
	left: 75%;
	right: auto
}
.large-pull-9 {
	right: 75%;
	left: auto
}
.large-10 {
	width: 83.3333%
}
html.oldie .large-10 {
	width: 83.25%
}
.large-offset-10 {
	margin-left: 83.3333%
}
html.oldie .large-offset-10 {
	margin-left: 83.25%
}
.large-push-10 {
	left: 83.3333%;
	right: auto
}
.large-pull-10 {
	right: 83.3333%;
	left: auto
}
.large-11 {
	width: 91.6667%
}
html.oldie .large-11 {
	width: 91.575%
}
.large-offset-11 {
	margin-left: 91.6667%
}
html.oldie .large-offset-11 {
	margin-left: 91.575%
}
.large-push-11 {
	left: 91.6667%;
	right: auto
}
.large-pull-11 {
	right: 91.6667%;
	left: auto
}
.large-12 {
	width: 100%
}
html.oldie .large-12 {
	width: 99.9%
}
.large-offset-12 {
	margin-left: 100%
}
html.oldie .large-offset-12 {
	margin-left: 99.9%
}
.large-push-12 {
	left: 100%;
	right: auto
}
.large-pull-12 {
	right: 100%;
	left: auto
}
.large-centered {
	margin-left: auto;
	margin-right: auto;
	float: none
}
.large-uncentered {
	margin-left: 0;
	margin-right: 0
}
.large-last {
	float: right
}
.large-notlast {
	float: left
}

@media only screen and (min-width: 1442px) {
.xlarge-offset-0 {
	margin-left: 0
}
.xlarge-push-0 {
	left: auto;
	right: auto
}
.xlarge-pull-0 {
	right: auto;
	left: auto
}
.xlarge-1 {
	width: 8.3333%
}
.xlarge-offset-1 {
	margin-left: 8.3333%
}
.xlarge-push-1 {
	left: 8.3333%;
	right: auto
}
.xlarge-pull-1 {
	right: 8.3333%;
	left: auto
}
.xlarge-2 {
	width: 16.6667%
}
.xlarge-offset-2 {
	margin-left: 16.6667%
}
.xlarge-push-2 {
	left: 16.6667%;
	right: auto
}
.xlarge-pull-2 {
	right: 16.6667%;
	left: auto
}
.xlarge-3 {
	width: 25%
}
.xlarge-offset-3 {
	margin-left: 25%
}
.xlarge-push-3 {
	left: 25%;
	right: auto
}
.xlarge-pull-3 {
	right: 25%;
	left: auto
}
.xlarge-4 {
	width: 33.3333%
}
.xlarge-offset-4 {
	margin-left: 33.3333%
}
.xlarge-push-4 {
	left: 33.3333%;
	right: auto
}
.xlarge-pull-4 {
	right: 33.3333%;
	left: auto
}
.xlarge-5 {
	width: 41.6667%
}
.xlarge-offset-5 {
	margin-left: 41.6667%
}
.xlarge-push-5 {
	left: 41.6667%;
	right: auto
}
.xlarge-pull-5 {
	right: 41.6667%;
	left: auto
}
.xlarge-6 {
	width: 50%
}
.xlarge-offset-6 {
	margin-left: 50%
}
.xlarge-push-6 {
	left: 50%;
	right: auto
}
.xlarge-pull-6 {
	right: 50%;
	left: auto
}
.xlarge-7 {
	width: 58.3333%
}
.xlarge-offset-7 {
	margin-left: 58.3333%
}
.xlarge-push-7 {
	left: 58.3333%;
	right: auto
}
.xlarge-pull-7 {
	right: 58.3333%;
	left: auto
}
.xlarge-8 {
	width: 66.6667%
}
.xlarge-offset-8 {
	margin-left: 66.6667%
}
.xlarge-push-8 {
	left: 66.6667%;
	right: auto
}
.xlarge-pull-8 {
	right: 66.6667%;
	left: auto
}
.xlarge-9 {
	width: 75%
}
.xlarge-offset-9 {
	margin-left: 75%
}
.xlarge-push-9 {
	left: 75%;
	right: auto
}
.xlarge-pull-9 {
	right: 75%;
	left: auto
}
.xlarge-10 {
	width: 83.3333%
}
.xlarge-offset-10 {
	margin-left: 83.3333%
}
.xlarge-push-10 {
	left: 83.3333%;
	right: auto
}
.xlarge-pull-10 {
	right: 83.3333%;
	left: auto
}
.xlarge-11 {
	width: 91.6667%
}
.xlarge-offset-11 {
	margin-left: 91.6667%
}
.xlarge-push-11 {
	left: 91.6667%;
	right: auto
}
.xlarge-pull-11 {
	right: 91.6667%;
	left: auto
}
.xlarge-12 {
	width: 100%
}
.xlarge-offset-12 {
	margin-left: 100%
}
.xlarge-push-12 {
	left: 100%;
	right: auto
}
.xlarge-pull-12 {
	right: 100%;
	left: auto
}
.xlarge-centered {
	margin-left: auto;
	margin-right: auto;
	float: none
}
.xlarge-uncentered {
	margin-left: 0;
	margin-right: 0
}
.xlarge-last {
	float: right
}
.xlarge-notlast {
	float: left
}
}

@media only screen and (max-width: 1068px) {
.medium-offset-0 {
	margin-left: 0
}
.medium-push-0 {
	left: auto;
	right: auto
}
.medium-pull-0 {
	right: auto;
	left: auto
}
.medium-1 {
	width: 8.3333%
}
.medium-offset-1 {
	margin-left: 8.3333%
}
.medium-push-1 {
	left: 8.3333%;
	right: auto
}
.medium-pull-1 {
	right: 8.3333%;
	left: auto
}
.medium-2 {
	width: 16.6667%
}
.medium-offset-2 {
	margin-left: 16.6667%
}
.medium-push-2 {
	left: 16.6667%;
	right: auto
}
.medium-pull-2 {
	right: 16.6667%;
	left: auto
}
.medium-3 {
	width: 25%
}
.medium-offset-3 {
	margin-left: 25%
}
.medium-push-3 {
	left: 25%;
	right: auto
}
.medium-pull-3 {
	right: 25%;
	left: auto
}
.medium-4 {
	width: 33.3333%
}
.medium-offset-4 {
	margin-left: 33.3333%
}
.medium-push-4 {
	left: 33.3333%;
	right: auto
}
.medium-pull-4 {
	right: 33.3333%;
	left: auto
}
.medium-5 {
	width: 41.6667%
}
.medium-offset-5 {
	margin-left: 41.6667%
}
.medium-push-5 {
	left: 41.6667%;
	right: auto
}
.medium-pull-5 {
	right: 41.6667%;
	left: auto
}
.medium-6 {
	width: 50%
}
.medium-offset-6 {
	margin-left: 50%
}
.medium-push-6 {
	left: 50%;
	right: auto
}
.medium-pull-6 {
	right: 50%;
	left: auto
}
.medium-7 {
	width: 58.3333%
}
.medium-offset-7 {
	margin-left: 58.3333%
}
.medium-push-7 {
	left: 58.3333%;
	right: auto
}
.medium-pull-7 {
	right: 58.3333%;
	left: auto
}
.medium-8 {
	width: 66.6667%
}
.medium-offset-8 {
	margin-left: 66.6667%
}
.medium-push-8 {
	left: 66.6667%;
	right: auto
}
.medium-pull-8 {
	right: 66.6667%;
	left: auto
}
.medium-9 {
	width: 75%
}
.medium-offset-9 {
	margin-left: 75%
}
.medium-push-9 {
	left: 75%;
	right: auto
}
.medium-pull-9 {
	right: 75%;
	left: auto
}
.medium-10 {
	width: 83.3333%
}
.medium-offset-10 {
	margin-left: 83.3333%
}
.medium-push-10 {
	left: 83.3333%;
	right: auto
}
.medium-pull-10 {
	right: 83.3333%;
	left: auto
}
.medium-11 {
	width: 91.6667%
}
.medium-offset-11 {
	margin-left: 91.6667%
}
.medium-push-11 {
	left: 91.6667%;
	right: auto
}
.medium-pull-11 {
	right: 91.6667%;
	left: auto
}
.medium-12 {
	width: 100%
}
.medium-offset-12 {
	margin-left: 100%
}
.medium-push-12 {
	left: 100%;
	right: auto
}
.medium-pull-12 {
	right: 100%;
	left: auto
}
.medium-centered {
	margin-left: auto;
	margin-right: auto;
	float: none
}
.medium-uncentered {
	margin-left: 0;
	margin-right: 0
}
.medium-last {
	float: right
}
.medium-notlast {
	float: left
}
}

@media only screen and (max-width: 735px) {
.small-offset-0 {
	margin-left: 0
}
.small-push-0 {
	left: auto;
	right: auto
}
.small-pull-0 {
	right: auto;
	left: auto
}
.small-1 {
	width: 8.3333%
}
.small-offset-1 {
	margin-left: 8.3333%
}
.small-push-1 {
	left: 8.3333%;
	right: auto
}
.small-pull-1 {
	right: 8.3333%;
	left: auto
}
.small-2 {
	width: 16.6667%
}
.small-offset-2 {
	margin-left: 16.6667%
}
.small-push-2 {
	left: 16.6667%;
	right: auto
}
.small-pull-2 {
	right: 16.6667%;
	left: auto
}
.small-3 {
	width: 25%
}
.small-offset-3 {
	margin-left: 25%
}
.small-push-3 {
	left: 25%;
	right: auto
}
.small-pull-3 {
	right: 25%;
	left: auto
}
.small-4 {
	width: 33.3333%
}
.small-offset-4 {
	margin-left: 33.3333%
}
.small-push-4 {
	left: 33.3333%;
	right: auto
}
.small-pull-4 {
	right: 33.3333%;
	left: auto
}
.small-5 {
	width: 41.6667%
}
.small-offset-5 {
	margin-left: 41.6667%
}
.small-push-5 {
	left: 41.6667%;
	right: auto
}
.small-pull-5 {
	right: 41.6667%;
	left: auto
}
.small-6 {
	width: 50%
}
.small-offset-6 {
	margin-left: 50%
}
.small-push-6 {
	left: 50%;
	right: auto
}
.small-pull-6 {
	right: 50%;
	left: auto
}
.small-7 {
	width: 58.3333%
}
.small-offset-7 {
	margin-left: 58.3333%
}
.small-push-7 {
	left: 58.3333%;
	right: auto
}
.small-pull-7 {
	right: 58.3333%;
	left: auto
}
.small-8 {
	width: 66.6667%
}
.small-offset-8 {
	margin-left: 66.6667%
}
.small-push-8 {
	left: 66.6667%;
	right: auto
}
.small-pull-8 {
	right: 66.6667%;
	left: auto
}
.small-9 {
	width: 75%
}
.small-offset-9 {
	margin-left: 75%
}
.small-push-9 {
	left: 75%;
	right: auto
}
.small-pull-9 {
	right: 75%;
	left: auto
}
.small-10 {
	width: 83.3333%
}
.small-offset-10 {
	margin-left: 83.3333%
}
.small-push-10 {
	left: 83.3333%;
	right: auto
}
.small-pull-10 {
	right: 83.3333%;
	left: auto
}
.small-11 {
	width: 91.6667%
}
.small-offset-11 {
	margin-left: 91.6667%
}
.small-push-11 {
	left: 91.6667%;
	right: auto
}
.small-pull-11 {
	right: 91.6667%;
	left: auto
}
.small-12 {
	width: 100%
}
.small-offset-12 {
	margin-left: 100%
}
.small-push-12 {
	left: 100%;
	right: auto
}
.small-pull-12 {
	right: 100%;
	left: auto
}
.small-centered {
	margin-left: auto;
	margin-right: auto;
	float: none
}
.small-uncentered {
	margin-left: 0;
	margin-right: 0
}
.small-last {
	float: right
}
.small-notlast {
	float: left
}
}
@font-face {
	font-style: normal;
	font-weight: 400;
}
@font-face {
	font-style: normal;
	font-weight: 200;
}
@font-face {
	font-style: normal;
	font-weight: 100;
}

.more {
}
.more:before, .more:after {
	content: ""
}
.more .icon-htc {
	x-content: ""
}
.more:before, .more:after, .more .icon-htc {
	display: inline-block;
	color: inherit;
	font-style: normal;
	font-weight: inherit;
	font-size: inherit;
	line-height: 1;
	text-decoration: none;
	position: relative;
	z-index: 1
}
.more:after, .more .icon-htc {
	top: 0;
	padding-left: 0.3em
}
.more:before {
	display: none
}
.more-block {
	margin-top: .5em
}
.ac-browser, .ac-familybrowser {
	position: relative;
	overflow: hidden;
	text-align: center
}
.ac-browser-wrapper, .ac-familybrowser-wrapper {
	margin-left: auto;
	margin-right: auto;
	position: relative
}
.ac-browser-items, .ac-familybrowser-items {
	margin: 0 34px;
	padding-bottom: 50px;
	list-style: none;
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch
}
html.oldie .ac-browser-items, html.oldie .ac-familybrowser-items {
	display: inline;
	zoom: 1
}

@media only screen and (orientation: landscape) {
.ac-browser-items, .ac-familybrowser-items {
	padding-bottom: 49px
}
}
html.touch .ac-browser-items, html.touch .ac-familybrowser-items {
	margin: 0;
	padding-left: 34px;
	padding-right: 34px
}
html.no-touch .ac-browser-items:before, html.no-touch .ac-familybrowser-items:before, html.no-touch .ac-browser-items:after, html.no-touch .ac-familybrowser-items:after {
	content: ' ';
	display: table
}
html.no-touch .ac-browser-items:after, html.no-touch .ac-familybrowser-items:after {
	clear: both
}
html.no-touch.oldie .ac-browser-items, html.no-touch.oldie .ac-familybrowser-items {
	zoom: 1
}
.ac-browser-item, .ac-familybrowser-item {
	display: inline-block;
	padding: 0
}
html.oldie .ac-browser-item, html.oldie .ac-familybrowser-item {
	display: inline;
	zoom: 1
}
.ac-browser-item:first-child, .ac-familybrowser-item:first-child {
	padding-left: 0 !important
}
.ac-browser-item:last-child, .ac-familybrowser-item:last-child {
	padding-right: 0 !important
}
html.oldie .ac-browser-paddles, html.oldie .ac-familybrowser-paddles {
	display: none
}
.ac-browser-paddle, .ac-familybrowser-paddle-left, .ac-familybrowser-paddle-right, .ac-browser-paddle-left, .ac-familybrowser-paddle-left, .ac-browser-paddle-right, .ac-familybrowser-paddle-right {
	font-size: 28px;
	line-height: 1;
	font-weight: 200;
	letter-spacing: normal;
	margin: 0;
	padding: 0;
	width: 34px;
	position: absolute;
	top: 0;
	bottom: 0;
	color: #333;
	background-color: transparent;
	opacity: 0;
	-webkit-transition: opacity 150ms ease-out, color 100ms ease-in-out;
	transition: opacity 150ms ease-out, color 100ms ease-in-out
}
html.oldie .ac-browser-paddle, html.oldie .ac-familybrowser-paddle-left, html.oldie .ac-familybrowser-paddle-right, html.oldie .ac-browser-paddle-left, html.oldie .ac-familybrowser-paddle-left, html.oldie .ac-browser-paddle-right, html.oldie .ac-familybrowser-paddle-right {
	font-weight: normal;
	font-family: "myriad-pro", "myriad pro", "Helvetica Neue", "Segoe UI", "Arial", sans-serif
}
.ac-browser-paddle:enabled, .ac-familybrowser-paddle-left:enabled, .ac-familybrowser-paddle-right:enabled, .ac-browser-paddle-left:enabled, .ac-familybrowser-paddle-left:enabled, .ac-browser-paddle-right:enabled, .ac-familybrowser-paddle-right:enabled {
	opacity: 1;
	cursor: pointer
}
.ac-browser-paddle:hover, .ac-familybrowser-paddle-left:hover, .ac-familybrowser-paddle-right:hover, .ac-browser-paddle-left:hover, .ac-familybrowser-paddle-left:hover, .ac-browser-paddle-right:hover, .ac-familybrowser-paddle-right:hover {
	color: rgba(51, 51, 51, 0.6)
}
.ac-browser-paddle .icon::after, .ac-familybrowser-paddle-left .icon::after, .ac-familybrowser-paddle-right .icon::after, .ac-browser-paddle-left .icon::after, .ac-familybrowser-paddle-left .icon::after, .ac-browser-paddle-right .icon::after, .ac-familybrowser-paddle-right .icon::after {
	padding: 0
}
html.touch .ac-browser-paddle, html.touch .ac-familybrowser-paddle-left, html.touch .ac-familybrowser-paddle-right, html.touch .ac-browser-paddle-left, html.touch .ac-familybrowser-paddle-left, html.touch .ac-browser-paddle-right, html.touch .ac-familybrowser-paddle-right {
	display: none
}
.ac-browser-paddle-left, .ac-familybrowser-paddle-left {
	left: 0;
	border-right: 1px solid rgba(102, 102, 102, 0.35)
}
.ac-familybrowser-theme-dark .ac-browser-paddle-left, .ac-familybrowser-theme-dark .ac-familybrowser-paddle-left {
	border-color: rgba(153, 153, 153, 0.35)
}
.ac-browser-paddle-left .icon, .ac-familybrowser-paddle-left .icon {
	margin-left: 8px
}
.ac-browser-paddle-right, .ac-familybrowser-paddle-right {
	right: 0;
	border-left: 1px solid rgba(102, 102, 102, 0.35)
}
.ac-familybrowser-theme-dark .ac-browser-paddle-right, .ac-familybrowser-theme-dark .ac-familybrowser-paddle-right {
	border-color: rgba(153, 153, 153, 0.35)
}
.ac-browser-paddle-right .icon, .ac-familybrowser-paddle-right .icon {
	margin-right: 8px
}
.ac-familybrowser {
	position: relative;
	width: 100%;
	/*top: 44px;*/
	z-index: 100;
	background: rgba(242,242,242,.6);
}
html.oldie .ac-familybrowser {
	background: #f7f7f7
}
.ac-familybrowser-theme-dark {
	background: rgba(0, 0, 0, 0.5)
}
html.oldie .ac-familybrowser-theme-dark {
	background: #7f7f7f
}

/*@media only screen and (max-width: 767px) {*/
/*.ac-familybrowser {*/
/*	top: 48px*/
/*}*/
/*}*/
.ac-familybrowser-wrapper {
	height: 110px;
	max-width: 100%
}

@media only screen and (max-width: 767px) {
.ac-familybrowser-wrapper {
	height: 113px
}
}
.ac-familybrowser-items {
	margin: 0 34px
}
.ac-familybrowser-item {
	padding: 0 20px
}
.ac-familybrowser-icon {
	margin-left: auto;
	margin-right: auto;
	display: block;
	height: 52px;
	background-position: center bottom;
	background-repeat: no-repeat
}
html.oldie .ac-familybrowser-icon {
	display: inline;
	zoom: 1
}
.ac-familybrowser-label, .ac-familybrowser-violator, .ac-familybrowser-label-violator {
	-webkit-transition: opacity 200ms linear;
	transition: opacity 200ms linear
}
.ac-familybrowser-label {
	font-size: 13px;
	line-height: 1;
	font-weight: 300;
	letter-spacing: 0.75px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	padding-top: 8px;
	white-space: normal;
	color: #333
}

@media only screen and (max-width: 735px) {
.ac-familybrowser-label {
	font-size: 14px;
	line-height: 1;
	font-weight: 400;
	letter-spacing: normal
}
}
html.oldie .ac-familybrowser-label {
	margin: 0
}

@media only screen and (max-width: 767px) {
.ac-familybrowser-label {
	font-size: 14px;
	line-height: 1;
	font-weight: 400;
	letter-spacing: normal
}
}
.ac-familybrowser-violator, .ac-familybrowser-label-violator {
	font-size: 10px;
	line-height: 1.2;
	font-weight: 400;
	letter-spacing: normal;
	position: absolute;
	z-index: 1;
	left: 0;
	width: 100%;
	color: #08c
}

@media only screen and (max-width: 735px) {
.ac-familybrowser-violator, .ac-familybrowser-label-violator {
	font-size: 12px;
	line-height: 1.1667;
	font-weight: 400;
	letter-spacing: normal
}
}

@media only screen and (max-width: 767px) {
.ac-familybrowser-violator, .ac-familybrowser-label-violator {
	font-size: 12px;
	line-height: 1.1667;
	font-weight: 400;
	letter-spacing: normal
}
}
.ac-familybrowser-link {
	position: relative;
	z-index: 1;
	display: inline-block;
	margin-top: 13px;
	margin-bottom: 26px;
	vertical-align: top;
	color: inherit
}
html.oldie .ac-familybrowser-link {
	display: inline;
	zoom: 1;
}
html.no-js .ac-familybrowser-link:hover {
	text-decoration: none
}
html.no-js .ac-familybrowser-link:hover .ac-familybrowser-label, html.no-js .ac-familybrowser-link:hover .ac-familybrowser-violator, html.no-js .ac-familybrowser-link:hover .ac-familybrowser-label-violator {
	opacity: 0.6
}
html.no-touch .ac-familybrowser-link:hover {
	text-decoration: none
}
html.no-touch .ac-familybrowser-link:hover .ac-familybrowser-label, html.no-touch .ac-familybrowser-link:hover .ac-familybrowser-violator, html.no-touch .ac-familybrowser-link:hover .ac-familybrowser-label-violator {
	opacity: 0.6
}
.ac-familybrowser-theme-dark .ac-familybrowser-label {
	color: #fff
}
.ac-familybrowser-theme-dark .ac-familybrowser-label-violator {
	color: #5cc3f6
}
.ac-familybrowser-paddle-left, .ac-familybrowser-paddle-right {
	margin: 12px 0
}
.ac-familybrowser-theme-dark .ac-familybrowser-paddle-left, .ac-familybrowser-theme-dark .ac-familybrowser-paddle-right {
	color: #fff
}
.ac-familybrowser-theme-dark .ac-familybrowser-paddle-left:hover, .ac-familybrowser-theme-dark .ac-familybrowser-paddle-right:hover {
	color: rgba(255, 255, 255, 0.6)
}
 @-webkit-keyframes fb-slidein-animation {
 0% {
 opacity: 0
}
 1% {
 -webkit-transform: translateX(160px);
 transform: translateX(160px)
}
 100% {
 opacity: 1;
 -webkit-transform: translateX(0);
 transform: translateX(0)
}
}
 @keyframes fb-slidein-animation {
 0% {
 opacity: 0
}
 1% {
 -webkit-transform: translateX(160px);
 transform: translateX(160px)
}
 100% {
 opacity: 1;
 -webkit-transform: translateX(0);
 transform: translateX(0)
}
}
.ac-familybrowser-item {
	-webkit-animation-name: fb-slidein-animation;
	animation-name: fb-slidein-animation;
	-webkit-animation-duration: 350ms;
	animation-duration: 350ms;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards
}
.ac-familybrowser-item-no-animation {
	-webkit-animation-name: fb-slidein-animation;
	animation-name: fb-slidein-animation;
	-webkit-animation-duration: 0ms;
	animation-duration: 0ms;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards
}
p {
	margin-bottom: 8px
}
a.block {
	display: block;
	cursor: pointer;
	text-decoration: none;
	color: inherit
}
html.oldie a.block {
}
a.block:before, a.block:after {
	content: ' ';
	display: table
}
a.block:after {
	clear: both
}
html.oldie a.block {
	zoom: 1
}
html.no-js a.block:hover {
	text-decoration: none
}
html.no-js a.block:hover .block-link {
	text-decoration: underline
}
html.no-js a.block:hover .block-link.icon-ie-parent {
	text-decoration: none
}
html.no-js a.block:hover .block-link.icon-ie-parent .icon-ie-link {
	text-decoration: underline
}
html.no-touch a.block:hover {
	text-decoration: none
}
html.no-touch a.block:hover .block-link {
	text-decoration: underline
}
html.no-touch a.block:hover .block-link.icon-ie-parent {
	text-decoration: none
}
html.no-touch a.block:hover .block-link.icon-ie-parent .icon-ie-link {
	text-decoration: underline
}
a.block .block-link {
	color: #08c;
	cursor: pointer
}
a.block-inline {
	display: inline-block
}
.button {
	background-color: #1d9bd9;
	background: -webkit-linear-gradient(#3baee7, #08c);
	background: linear-gradient(#3baee7, #08c);
	border-radius: 4px;
	color: #fff;
	cursor: pointer;
	font-size: 15px;
	font-weight: 500;
	line-height: 32px;
	text-align: center;
	white-space: nowrap;
	padding: 0 16px;
	display: inline-block;
	font-family: "myriad-pro", "myriad pro", "Helvetica Neue", "Segoe UI", "Arial", sans-serif
}
html.oldie .button {
	display: inline;
	zoom: 1;
	vertical-align: middle
}
html.no-js .button:hover {
	background-color: #2fa2dc;
	background: -webkit-linear-gradient(#4ab4e8, #1491d0);
	background: linear-gradient(#4ab4e8, #1491d0);
	text-decoration: none
}
html.no-touch .button:hover {
	background-color: #2fa2dc;
	background: -webkit-linear-gradient(#4ab4e8, #1491d0);
	background: linear-gradient(#4ab4e8, #1491d0);
	text-decoration: none
}
.button:active {
	background-color: #0d85c0;
	background: -webkit-linear-gradient(#1b9dde, #006da3);
	background: linear-gradient(#1b9dde, #006da3);
	outline: none
}
 @-moz-document url-prefix() {
 .button {
 line-height: 31px;
 padding-top: 1px
}
}
.button-buynow, .button-compact {
	background-color: #1d9bd9;
	background: -webkit-linear-gradient(#3baee7, #08c);
	background: linear-gradient(#3baee7, #08c);
	border-color: #137ec6;
	border-top-color: #1992da;
	border-bottom-color: #0d6ab2;
	color: #fff;
	color: #fff;
	font-size: 13px;
	padding: 0 11px;
	line-height: 22px
}
html.no-js .button-buynow:hover, html.no-js .button-compact:hover {
	background: #2fa2dc;
	background: -webkit-linear-gradient(#4ab4e8, #1491d0);
	background: linear-gradient(#4ab4e8, #1491d0)
}
html.no-touch .button-buynow:hover, html.no-touch .button-compact:hover {
	background: #2fa2dc;
	background: -webkit-linear-gradient(#4ab4e8, #1491d0);
	background: linear-gradient(#4ab4e8, #1491d0)
}
.button-buynow:active, .button-compact:active {
	background: #0d85c0;
	background: -webkit-linear-gradient(#1b9dde, #006da3);
	background: linear-gradient(#1b9dde, #006da3)
}
 @-moz-document url-prefix() {
 .button-buynow, .button-compact {
 line-height: 21px;
 padding-top: 1px
}
}
.dotnav {
	text-align: center
}
html.touch .dotnav {
	pointer-events: none
}
.dotnav ul {
	display: inline-block
}
html.oldie .dotnav ul {
	display: inline;
	zoom: 1
}
.dotnav li {
	list-style: none;
	margin: 0 10px;
	width: 8px;
	height: 8px;
	float: left;
	position: relative
}
.dotnav .dotnav-item {
	top: 0;
	left: 0;
	width: 8px;
	height: 8px;
	outline: none;
	position: absolute;
	border-radius: 50%;
	background-color: #999;
	box-sizing: content-box;
	padding: 0;
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap
}
html.no-js .dotnav .dotnav-item:hover {
	background-color: #666
}
html.no-touch .dotnav .dotnav-item:hover {
	background-color: #666
}
.dotnav .dotnav-item:focus {
	outline-style: auto;
	outline-color: #08c
}
.dotnav .dotnav-item.current {
	top: -1px;
	left: -1px;
	width: 8px;
	height: 8px;
	border-style: solid;
	border-width: 1px;
	border-color: #08c;
	background-color: transparent;
	cursor: default
}
html.no-js .dotnav .dotnav-item.current:hover {
	background-color: transparent
}
html.no-touch .dotnav .dotnav-item.current:hover {
	background-color: transparent
}
.paddlenav {
	list-style: none;
	margin: 0
}
.paddlenav-arrow {
	width: 67px;
	height: 128px;
	position: absolute;
	top: 50%;
	line-height: 1.78;
	margin-top: -64px;
	text-align: center;
	overflow: hidden;
	color: #999;
	background-color: transparent;
	border-radius: 4px;
	display: block;
	font-size: 72px;
	font-style: normal;
	font-weight: 200;
	opacity: .70;
	-webkit-transition: 250ms opacity linear, 250ms color linear, 250ms background-color linear;
	transition: 250ms opacity linear, 250ms color linear, 250ms background-color linear
}
.paddlenav-arrow.disabled {
	opacity: 0
}
html.no-js .paddlenav-arrow.disabled:hover {
	opacity: 0;
	cursor: default
}
html.no-touch .paddlenav-arrow.disabled:hover {
	opacity: 0;
	cursor: default
}
html.oldie .paddlenav-arrow.disabled {
	display: none
}
html.no-js .paddlenav-arrow:hover {
	color: #999;
	background-color: transparent;
	text-decoration: none;
	opacity: 1
}
html.no-touch .paddlenav-arrow:hover {
	color: #999;
	background-color: transparent;
	text-decoration: none;
	opacity: 1
}
.paddlenav-arrow:focus {
	color: #999;
	background-color: transparent;
	text-decoration: none
}
html.touch .paddlenav-arrow {
	color: #999;
	background-color: transparent;
	text-decoration: none
}
.paddlenav-arrow-left {
	left: 0;
}
.paddlenav-arrow-left:before, .paddlenav-arrow-left:after {
	content: ""
}
.paddlenav-arrow-left .icon-htc {
	x-content: ""
}
.paddlenav-arrow-left:before, .paddlenav-arrow-left:after, .paddlenav-arrow-left .icon-htc {
	display: inline-block;
	color: inherit;
	font-style: normal;
	font-weight: inherit;
	font-size: inherit;
	line-height: 1;
	text-decoration: none;
	position: relative;
	z-index: 1
}
.paddlenav-arrow-left:after, .paddlenav-arrow-left .icon-htc {
	top: 0;
	padding-left: 0;
	padding-right: 0
}
.paddlenav-arrow-left:before {
	display: none
}
.paddlenav-arrow-right {
	right: 0;
}
.paddlenav-arrow-right:before, .paddlenav-arrow-right:after {
	content: ""
}
.paddlenav-arrow-right .icon-htc {
	x-content: ""
}
.paddlenav-arrow-right:before, .paddlenav-arrow-right:after, .paddlenav-arrow-right .icon-htc {
	display: inline-block;
	color: inherit;
	font-style: normal;
	font-weight: inherit;
	font-size: inherit;
	line-height: 1;
	text-decoration: none;
	position: relative;
	z-index: 1
}
.paddlenav-arrow-right:after, .paddlenav-arrow-right .icon-htc {
	top: 0;
	padding-left: 0;
	padding-right: 0
}
.paddlenav-arrow-right:before {
	display: none
}
html.no-touch .paddlenav-onhover .paddlenav-arrow {
	opacity: 0
}
html.no-touch .paddlenav-onhover .paddlenav-arrow:focus {
	opacity: 1
}
html.oldie .paddlenav-onhover .paddlenav-arrow {
	visibility: hidden
}
.with-paddlenav {
	position: relative
}
html.no-js.no-touch .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow {
	opacity: .70
}
html.no-js.oldie .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow {
	visibility: visible
}
html.no-js .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow:focus {
	opacity: 1
}
html.no-js .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow:hover {
	opacity: 1
}
html.no-js.no-touch .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow:hover {
	opacity: 1
}
html.no-js .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow.disabled {
	opacity: 0
}
html.no-touch .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow {
	opacity: .70
}
html.no-touch.oldie .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow {
	visibility: visible
}
html.no-touch .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow:focus {
	opacity: 1
}
html.no-touch.no-js .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow:hover {
	opacity: 1
}
html.no-touch .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow:hover {
	opacity: 1
}
html.no-touch .with-paddlenav:hover .paddlenav-onhover .paddlenav-arrow.disabled {
	opacity: 0
}
.paddlenav-framed .paddlenav-arrow {
	color: #999;
	background-color: #fafafa
}
html.no-js .paddlenav-framed .paddlenav-arrow:hover {
	color: #999;
	background-color: #fafafa;
	text-decoration: none
}
html.no-touch .paddlenav-framed .paddlenav-arrow:hover {
	color: #999;
	background-color: #fafafa;
	text-decoration: none
}
.paddlenav-framed .paddlenav-arrow:focus {
	color: #999;
	background-color: #fafafa;
	text-decoration: none
}
html.touch .paddlenav-framed .paddlenav-arrow {
	color: #999;
	background-color: #fafafa
}
.paddlenav-framed .paddlenav-arrow-left {
	left: 15px
}
.paddlenav-framed .paddlenav-arrow-right {
	right: 15px
}
.togglenav {
	margin: 0 auto;
	padding: 0;
	width: 100%;
	text-align: center;
	position: relative
}

@media only screen and (max-width: 735px) {
.togglenav {
	white-space: nowrap;
	overflow: scroll;
	-webkit-overflow-scrolling: touch
}
}
.togglenav ul {
	display: inline-block
}
html.oldie .togglenav ul {
	display: inline;
	zoom: 1
}
.togglenav li {
	float: left;
	list-style: none;
	outline: none
}

@media only screen and (max-width: 735px) {
.togglenav li {
	display: inline-block;
	float: none
}
}
.togglenav-button {
	padding: 10px 15px 9px;
	font-size: 1em;
	line-height: 1;
	color: #333;
	text-decoration: none;
	display: block;
	margin-left: -1px;
	position: relative;
	z-index: 0
}
html.no-js .togglenav-button:hover {
	color: #08c;
	text-decoration: none
}
html.no-touch .togglenav-button:hover {
	color: #08c;
	text-decoration: none
}
.togglenav-button.current {
	pointer-events: none;
	color: #999;
	text-decoration: none;
	cursor: default;
	z-index: 10
}
html.no-js .togglenav-button.current:hover {
	color: #999
}
html.no-touch .togglenav-button.current:hover {
	color: #999
}
.togglenav-button:after {
	content: '•';
	font-size: 10px;
	vertical-align: middle;
	position: relative;
	left: 15px;
	color: #333
}
li:last-child .togglenav-button:after {
	content: ''
}
.align-middle {
	float: none;
	display: inline-block;
	vertical-align: middle
}
html.oldie .align-middle {
	display: inline;
	zoom: 1
}
.align-bottom {
	float: none;
	display: inline-block;
	vertical-align: bottom
}
html.oldie .align-bottom {
	display: inline;
	zoom: 1
}
.ac-familybrowser-item {
	padding-left: 20px;
	padding-right: 20px
}
.ac-familybrowser-item.compare {
	padding-left: 18px;
	padding-right: 18px
}
.ac-familybrowser-item.compare .ac-familybrowser-icon {
	background-repeat: no-repeat;
	background-size: 70px 54px;
	height: 54px;
	width: 70px
}
html.no-svg .ac-familybrowser-item.compare .ac-familybrowser-icon {
	background-size: 70px 54px;
	height: 54px;
	width: 70px
}
.ac-familybrowser-theme-dark .ac-familybrowser-item.compare .ac-familybrowser-icon {
	background-repeat: no-repeat;
	background-size: 70px 54px;
	height: 54px;
	width: 70px
}
html.no-svg .ac-familybrowser-theme-dark .ac-familybrowser-item.compare .ac-familybrowser-icon {
	background-size: 70px 54px;
	height: 54px;
	width: 70px
}
html.oldie body {
	position: relative;
	min-width: 1069px
}
.desktop-only {
	display: block
}

@media only screen and (max-width: 735px) {
.desktop-only {
	display: none
}
}
.small-only {
	display: none
}

@media only screen and (max-width: 735px) {
.small-only {
	display: block
}
}
.nowrap {
	white-space: nowrap
}
.main {
	overflow-x: hidden
}
.main.update {
	overflow: hidden
}

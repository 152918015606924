html, body {
	margin: 0;
	padding: 0
}
#ac-globalnav, #ac-gn-segmentbar {
	font-weight: normal;
	text-align: left;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%
}
#ac-globalnav, #ac-globalnav:before, #ac-globalnav:after, #ac-globalnav *, #ac-globalnav *:before, #ac-globalnav *:after, #ac-gn-segmentbar, #ac-gn-segmentbar:before, #ac-gn-segmentbar:after, #ac-gn-segmentbar *, #ac-gn-segmentbar *:before, #ac-gn-segmentbar *:after {
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    pointer-events: auto;
    letter-spacing: 0.75px;
	 font-weight: 300!important;
}
#ac-globalnav *, #ac-globalnav *:before, #ac-globalnav *:after, #ac-gn-segmentbar *, #ac-gn-segmentbar *:before, #ac-gn-segmentbar *:after {
	font-size: 1em;
	font-family: inherit;
*font-family:expression(this.parentNode.currentStyle['font-family']);
	font-weight: inherit;
/**font-weight:expression(this.parentNode.currentStyle['font-weight']);*/
	line-height: inherit;
/**line-height:expression(this.parentNode.currentStyle['line-height']);*/
	text-align: inherit;
/**text-align:expression(this.parentNode.currentStyle['text-align'])*/
}
#ac-globalnav article, #ac-globalnav aside, #ac-globalnav details, #ac-globalnav figcaption, #ac-globalnav figure, #ac-globalnav footer, #ac-globalnav header, #ac-globalnav nav, #ac-globalnav section, #ac-gn-segmentbar article, #ac-gn-segmentbar aside, #ac-gn-segmentbar details, #ac-gn-segmentbar figcaption, #ac-gn-segmentbar figure, #ac-gn-segmentbar footer, #ac-gn-segmentbar header, #ac-gn-segmentbar nav, #ac-gn-segmentbar section {
	display: block
}
#ac-globalnav img, #ac-gn-segmentbar img {
	border: 0;
	vertical-align: middle
}
#ac-globalnav ul, #ac-gn-segmentbar ul {
	list-style: none
}
#ac-globalnav, #ac-globalnav select, #ac-globalnav button, #ac-gn-segmentbar, #ac-gn-segmentbar select, #ac-gn-segmentbar button {
	font-family: "myriad-pro", "myriad pro", "Helvetica Neue", "Segoe UI", "Arial", sans-serif;
	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: 'liga', 'kern'
}
#ac-globalnav input, #ac-globalnav textarea, #ac-gn-segmentbar input, #ac-gn-segmentbar textarea {
	font-family: "myriad-pro", "myriad pro", "Helvetica Neue", "Segoe UI", "Arial", sans-serif;
	font-synthesis: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: 'liga', 'kern'
}
#ac-globalnav {
position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    margin: 0;
    width: 100%;
    min-width: 1024px;
    height: 48px;
    max-height: 44px;
    background: #069;
    background: rgba(00,83,138,.85);
    font-size: 18px;
    -webkit-user-select: none;
}
.ac-theme-dark #ac-globalnav {
	background: #2b2b2b;
	background: rgba(85,85,85,0.5)
}

@media only screen and (max-width: 1023px) {
#ac-globalnav {
	min-width: 320px
}
}

@media only screen and (max-width: 840px) {
#ac-globalnav {
	max-height: none;
	-webkit-transition: background 0.35s linear, height 0.35s ease-in;
	transition: background 0.35s linear, height 0.35s ease-in
} 
#ac-gn-menustate:checked ~ #ac-globalnav, #ac-gn-menustate:target ~ #ac-globalnav {
        height: 100%;
        background: #006699;
        -webkit-transition: background 0.35s linear, height 0.35s ease-in 200ms;
        transition: background 0.35s linear, height 0.35s ease-in 200ms

}
}
#ac-gn-menustate {
	display: none
}
#ac-gn-placeholder {
	height: 44px
}

@media only screen and (max-width: 840px) {
#ac-gn-placeholder {
	height: 48px
}
}
.ac-nav-overlap #ac-gn-placeholder {
	display: none
}
@-webkit-keyframes ac-gn-curtain-show {
0% {
opacity:0;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1
}
}
@keyframes ac-gn-curtain-show {
0% {
opacity:0;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1
}
}
#ac-gn-curtain {
	background: rgba(0,0,0,0.2);
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9998
}

@media only screen and (max-width: 419px) {
#ac-globalnav.with-bagview ~ #ac-gn-curtain {
	display: block;
	-webkit-animation: ac-gn-curtain-show 200ms both;
	animation: ac-gn-curtain-show 200ms both
}
}
#ac-globalnav.searchshow ~ #ac-gn-curtain, #ac-globalnav.searchopen ~ #ac-gn-curtain, #ac-globalnav.searchhide ~ #ac-gn-curtain {
	display: block
}

@media only screen and (max-width: 840px) {
#ac-globalnav.searchshow ~ #ac-gn-curtain, #ac-globalnav.searchopen ~ #ac-gn-curtain, #ac-globalnav.searchhide ~ #ac-gn-curtain {
	display: none
}
}
#ac-globalnav.searchshow ~ #ac-gn-curtain {
	-webkit-animation: ac-gn-curtain-show 200ms both;
	animation: ac-gn-curtain-show 200ms both
}
#ac-globalnav.searchhide ~ #ac-gn-curtain {
	-webkit-animation: ac-gn-curtain-show 200ms reverse both;
	animation: ac-gn-curtain-show 200ms reverse both
}
#ac-globalnav .ac-gn-content {
	margin: 0 auto;
	max-width: 980px;
	padding: 0 22px;
	position: relative;
	z-index: 2
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-content {
	padding: 0;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%
}
}
@-webkit-keyframes ac-gn-xtek-searchshow {
0% {
-webkit-transform:none;
transform:none;
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translate3d(0, -100%, 0);
transform:translate3d(0, -100%, 0)
}
}
@keyframes ac-gn-xtek-searchshow {
0% {
-webkit-transform:none;
transform:none;
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translate3d(0, -100%, 0);
transform:translate3d(0, -100%, 0)
}
}
@-webkit-keyframes ac-gn-xtek-searchhide {
0% {
-webkit-transform:translate3d(0, -100%, 0);
transform:translate3d(0, -100%, 0);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:none;
transform:none
}
}
@keyframes ac-gn-xtek-searchhide {
0% {
-webkit-transform:translate3d(0, -100%, 0);
transform:translate3d(0, -100%, 0);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:none;
transform:none
}
}
#ac-globalnav .ac-gn-header {
	display: none;
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 48px;
	overflow: hidden
}
#ac-globalnav .ac-gn-header .ac-gn-xtek {
	position: absolute;
	width: 48px;
	top: 0;
	left: 50%;
	margin-left: -24px;
	text-align: center;
	z-index: 1
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-header {
	display: block
}
#ac-globalnav.searchshow .ac-gn-header, #ac-globalnav.searchopen .ac-gn-header, #ac-globalnav.searchhide .ac-gn-header {
	background: #000;
	-webkit-transform: translateZ(0);
	transform: translateZ(0)
}
#ac-globalnav .ac-gn-header .ac-gn-xtek {
	display: block
}
#ac-globalnav.searchshow .ac-gn-header .ac-gn-xtek {
	-webkit-animation: ac-gn-xtek-searchshow 600ms 200ms both;
	animation: ac-gn-xtek-searchshow 600ms 200ms both
}
#ac-globalnav.searchopen .ac-gn-header .ac-gn-xtek {
	display: none
}
#ac-globalnav.searchhide .ac-gn-header .ac-gn-xtek {
	-webkit-animation: ac-gn-xtek-searchhide 600ms 200ms both;
	animation: ac-gn-xtek-searchhide 600ms 200ms both
}
}
@-webkit-keyframes ac-gn-list-searchshow {
0% {
-webkit-transform:translateY(0);
transform:translateY(0);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(-100%) translateY(106px);
transform:translateY(-100%) translateY(106px)
}
}
@keyframes ac-gn-list-searchshow {
0% {
-webkit-transform:translateY(0);
transform:translateY(0);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(-100%) translateY(106px);
transform:translateY(-100%) translateY(106px)
}
}
#ac-globalnav .ac-gn-list {
	cursor: default;
	margin: 0 -10px;
	text-align: justify;
	width: auto;
	height: 44px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: justify;
	-ms-text-justify: distribute-all-lines;
	text-justify: distribute-all-lines
}
#ac-globalnav .ac-gn-list:after {
	content: '';
	width: 100%;
	display: inline-block;
	font-size: 0;
	line-height: 0
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-list {
	position: absolute;
	z-index: 2;
	top: 48px;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 0;
	padding: 0 48px;
	height: auto;
	box-sizing: border-box;
	overflow: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	visibility: hidden;
	-webkit-transition: visibility 0s linear 1s;
	transition: visibility 0s linear 1s
}
#ac-globalnav .ac-gn-list :nth-child(2) {
	margin-top: 12px
}
#ac-globalnav .ac-gn-list :nth-child(9) {
	margin-bottom: 48px
}
#ac-gn-menustate:checked ~ #ac-globalnav .ac-gn-list, #ac-gn-menustate:target ~ #ac-globalnav .ac-gn-list {
	visibility: visible;
	-webkit-transition-delay: 0s;
	transition-delay: 0s
}
#ac-globalnav.searchshow .ac-gn-list {
	bottom: auto;
	-webkit-animation: ac-gn-list-searchshow 800ms both;
	animation: ac-gn-list-searchshow 800ms both
}
#ac-globalnav.searchopen .ac-gn-list {
	display: none
}
#ac-globalnav.searchhide .ac-gn-list {
	bottom: auto;
	-webkit-animation: ac-gn-list-searchshow 800ms reverse both;
	animation: ac-gn-list-searchshow 800ms reverse both
}
#ac-globalnav .ac-gn-list:after {
	display: none
}
}
@-webkit-keyframes ac-gn-item-searchshow {
0% {
opacity:1;
-webkit-transform:scale(1);
transform:scale(1);
-webkit-animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1);
animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1)
}
40% {
opacity:1
}
100% {
opacity:0;
-webkit-transform:scale(0.7);
transform:scale(0.7)
}
}
@keyframes ac-gn-item-searchshow {
0% {
opacity:1;
-webkit-transform:scale(1);
transform:scale(1);
-webkit-animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1);
animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1)
}
40% {
opacity:1
}
100% {
opacity:0;
-webkit-transform:scale(0.7);
transform:scale(0.7)
}
}
@-webkit-keyframes ac-gn-item-searchhide {
0% {
opacity:0;
-webkit-transform:scale(0.7);
transform:scale(0.7)
}
60% {
opacity:1
}
100% {
opacity:1;
-webkit-transform:scale(1);
transform:scale(1);
-webkit-animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1);
animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1)
}
}
@keyframes ac-gn-item-searchhide {
0% {
opacity:0;
-webkit-transform:scale(0.7);
transform:scale(0.7)
}
60% {
opacity:1
}
100% {
opacity:1;
-webkit-transform:scale(1);
transform:scale(1);
-webkit-animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1);
animation-timing-function:cubic-bezier(0.2727, 0.0986, 0.8333, 1)
}
}
@-webkit-keyframes ac-gn-item-searchshow-small {
0% {
-webkit-transform:none;
transform:none;
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(-50px);
transform:translateY(-50px)
}
}
@keyframes ac-gn-item-searchshow-small {
0% {
-webkit-transform:none;
transform:none;
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(-50px);
transform:translateY(-50px)
}
}
@-webkit-keyframes ac-gn-search-placeholder-searchshow {
0% {
opacity:1;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0.4
}
}
@keyframes ac-gn-search-placeholder-searchshow {
0% {
opacity:1;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0.4
}
}
@-webkit-keyframes ac-gn-bag-searchshow {
0% {
opacity:1;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0
}
}
@keyframes ac-gn-bag-searchshow {
0% {
opacity:1;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0
}
}
#ac-globalnav .ac-gn-item {
	display: inline-block;
	position: relative;
	height: 44px;
	z-index: 1;
	vertical-align: top
}
#ac-globalnav.ie7 .ac-gn-item {
	display: inline;
	zoom: 1
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-item {
	width: 100%;
	height: 48px
}
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-xtek {
	display: none
}
}

#ac-globalnav .ac-gn-link {
	font-size: 16px;
	line-height: 2.75;
	font-weight: 400;
	letter-spacing: normal;
	opacity: 1;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=100);
	color: #fff;
	position: relative;
	z-index: 1;
	display: inline-block;

	height: 44px;
	background: no-repeat;
	text-decoration: none;
	white-space: nowrap;
	-webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-tap-highlight-color: transparent;
	outline-offset: -7px
}
#ac-globalnav .ac-gn-language {
	font-size: 11px;
	line-height: 4;

	font-weight: 400;
	letter-spacing: normal;
	opacity: 1;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=100);
	color: #fff;
	position: relative;
	z-index: 1;
	display: inline-block;
	

	height: 44px;
	background: no-repeat;
	text-decoration: none;
	white-space: nowrap;
	-webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-tap-highlight-color: transparent;
	outline-offset: -7px
}

@media only screen and (max-width: 840px) {

#ac-globalnav .ac-gn-language {
	font-size: 18px!important;
}

#ac-globalnav .ac-gn-link {
	
	line-height: 2.35;
	font-weight: 300;
	letter-spacing: normal
}
#ac-globalnav .ac-gn-language {
	
font-size: 11px;
	line-height: 4;

	font-weight: 400;
	letter-spacing: normal;
	opacity: 1;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=100);
	color: #fff;
	position: relative;
	z-index: 1;
	display: inline-block;
	
	margin-left: 0px;
	margin-right: 0px;
	height: 44px;
	background: no-repeat;
	text-decoration: none;
	white-space: nowrap;
	-webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-tap-highlight-color: transparent;
	outline-offset: -7px
}
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-link {
	height: 48px
}
}
#ac-globalnav.no-js .ac-gn-link:hover {
	opacity: 0.65;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=65)
}
#ac-globalnav.no-touch .ac-gn-link:hover {
	opacity: 0.65;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=65)
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-item-menu .ac-gn-link {
	background-position: left center;
	display: block;
	outline-offset: 0;
	padding: 0;
	width: auto;
	max-width: none
}
}
#ac-globalnav .ac-gn-link-xtek {
	background-repeat: no-repeat;
	background-size: 20px 44px;
	background-position: center center;
	width: 20px
}
#ac-globalnav.no-svg .ac-gn-link-xtek {
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-link-xtek {
	background-size: 21px 48px;
}

}
.ac-gn-current-xtek #ac-globalnav .ac-gn-link-xtek {
	opacity: 0.65;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=65)
}

@media only screen and (max-width: 840px) {
#ac-globalnav  {
	background-size: 35px 48px;
}

}
.ac-gn-current-ipad #ac-globalnav {
	opacity: 0.65;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=65)
}

#ac-globalnav .ac-gn-link.current {
	opacity: 0.65;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=$ieopacity)';
	filter: alpha(opacity=65)
}
#ac-globalnav .ac-gn-menuicon {
	display: none;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 48px;
	border-bottom: none
}

@media only screen and (max-width: 840px) {
#ac-globalnav .ac-gn-menuicon {
	display: block
}
}
#ac-globalnav .ac-gn-menuicon-label {
	display: block;
	position: absolute;
	z-index: 3;
	top: 0;
	width: 48px;
	height: 48px;
	cursor: pointer;
	-webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	-webkit-tap-highlight-color: transparent
}
#ac-globalnav .ac-gn-menuicon-label:hover, #ac-globalnav .ac-gn-menuicon-label:focus, #ac-globalnav .ac-gn-menuicon-label:active {
	opacity: .65
}
#ac-globalnav.touch .ac-gn-menuicon-label {
	opacity: 1;
	-webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99)
}
#ac-gn-menustate:checked ~ #ac-globalnav .ac-gn-menuicon-label, #ac-gn-menustate:target ~ #ac-globalnav .ac-gn-menuicon-label {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg)
}
#ac-globalnav .ac-gn-menuicon-bread {
	position: absolute;
	z-index: 3;
	top: 0;
	left: 0;
	width: 48px;
	height: 48px;
	-webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)
}
#ac-globalnav .ac-gn-menuicon-bread-top {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	-webkit-transform: rotate(0);
	transform: rotate(0);
	z-index: 4
}
#ac-gn-menustate:checked ~ #ac-globalnav .ac-gn-menuicon-bread-top, #ac-gn-menustate:target ~ #ac-globalnav .ac-gn-menuicon-bread-top {
	-webkit-transition: -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}
#ac-globalnav .ac-gn-menuicon-bread-bottom {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	-webkit-transform: rotate(0);
	transform: rotate(0)
}
#ac-gn-menustate:checked ~ #ac-globalnav .ac-gn-menuicon-bread-bottom, #ac-gn-menustate:target ~ #ac-globalnav .ac-gn-menuicon-bread-bottom {
	-webkit-transition: -webkit-transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: transform 0.25s 0.2s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}
#ac-globalnav .ac-gn-menuicon-bread-crust {
	display: block;
	width: 17px;
	height: 1px;
	background: #fff;
	position: absolute;
	left: 16px;
	z-index: 1;
	-webkit-transition: -webkit-transform 0.2s;
	transition: transform 0.2s
}
#ac-globalnav .ac-gn-menuicon-bread-crust-top {
	top: 23px;
	-webkit-transition: -webkit-transform 0.2s 0.2s;
	transition: transform 0.2s 0.2s;
	-webkit-transform: translateY(-3px);
	transform: translateY(-3px)
}
#ac-gn-menustate:checked ~ #ac-globalnav .ac-gn-menuicon-bread-crust-top, #ac-gn-menustate:target ~ #ac-globalnav .ac-gn-menuicon-bread-crust-top {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition-delay: 0ms;
	transition-delay: 0ms;
}
#ac-globalnav .ac-gn-menuicon-bread-crust-bottom {
	bottom: 23px;
	-webkit-transform: translateY(3px);
	transform: translateY(3px)
}
#ac-gn-menustate:checked ~ #ac-globalnav .ac-gn-menuicon-bread-crust-bottom, #ac-gn-menustate:target ~ #ac-globalnav .ac-gn-menuicon-bread-crust-bottom {
	-webkit-transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	-webkit-transform: translateY(0);
	transform: translateY(0)
}
#ac-globalnav .ac-gn-menuanchor {
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 1px;
	z-index: 10
}
#ac-globalnav .ac-gn-menuanchor:focus {
	outline-offset: -8px;
	width: 48px;
	height: 48px
}
#ac-globalnav .ac-gn-menuanchor-close {
	display: none
}
#ac-globalnav .ac-gn-menuanchor-label {
	position: absolute;
	top: 0;
	left: 0;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip-path: inset(0px 0px 99.9% 99.9%);
	clip-path: inset(0px 0px 99.9% 99.9%);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0
}

#ac-gn-menustate:checked ~ #ac-globalnav .ac-gn-menuanchor-close, #ac-gn-menustate:target ~ #ac-globalnav .ac-gn-menuanchor-close {
	display: block
}

@-webkit-keyframes loader {
from {
-webkit-transform:rotate(0deg) translateZ(0);
transform:rotate(0deg) translateZ(0)
}
to {
-webkit-transform:rotate(360deg) translateZ(0);
transform:rotate(360deg) translateZ(0)
}
}
@keyframes loader {
from {
-webkit-transform:rotate(0deg) translateZ(0);
transform:rotate(0deg) translateZ(0)
}
to {
-webkit-transform:rotate(360deg) translateZ(0);
transform:rotate(360deg) translateZ(0)
}
}

@-webkit-keyframes ac-gn-searchview-searchhide {
0% {
opacity:1;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0
}
}
@keyframes ac-gn-searchview-searchhide {
0% {
opacity:1;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0
}
}
@-webkit-keyframes ac-gn-searchview-searchshow-small {
0% {
opacity:0;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1
}
}
@keyframes ac-gn-searchview-searchshow-small {
0% {
opacity:0;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1
}
}
@-webkit-keyframes ac-gn-searchview-close-left-searchshow {
0% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1;
-webkit-transform:rotate(-45deg);
transform:rotate(-45deg)
}
}
@keyframes ac-gn-searchview-close-left-searchshow {
0% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1;
-webkit-transform:rotate(-45deg);
transform:rotate(-45deg)
}
}
@-webkit-keyframes ac-gn-searchview-close-left-searchhide {
0% {
opacity:1;
-webkit-transform:rotate(-45deg);
transform:rotate(-45deg);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1)
}
}
@keyframes ac-gn-searchview-close-left-searchhide {
0% {
opacity:1;
-webkit-transform:rotate(-45deg);
transform:rotate(-45deg);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1)
}
}
@-webkit-keyframes ac-gn-searchview-close-right-searchshow {
0% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1;
-webkit-transform:rotate(45deg);
transform:rotate(45deg)
}
}
@keyframes ac-gn-searchview-close-right-searchshow {
0% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1;
-webkit-transform:rotate(45deg);
transform:rotate(45deg)
}
}
@-webkit-keyframes ac-gn-searchview-close-right-searchhide {
0% {
opacity:1;
-webkit-transform:rotate(45deg);
transform:rotate(45deg);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1)
}
}
@keyframes ac-gn-searchview-close-right-searchhide {
0% {
opacity:1;
-webkit-transform:rotate(45deg);
transform:rotate(45deg);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:0;
-webkit-transform:scale3d(1, 0.65, 1);
transform:scale3d(1, 0.65, 1)
}
}
@-webkit-keyframes ac-gn-searchview-close-searchshow-small {
0% {
-webkit-transform:translateY(100%);
transform:translateY(100%);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(0);
transform:translateY(0)
}
}
@keyframes ac-gn-searchview-close-searchshow-small {
0% {
-webkit-transform:translateY(100%);
transform:translateY(100%);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(0);
transform:translateY(0)
}
}
@-webkit-keyframes ac-gn-searchview-close-searchhide-small {
0% {
-webkit-transform:translateY(0);
transform:translateY(0);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(100%);
transform:translateY(100%)
}
}
@keyframes ac-gn-searchview-close-searchhide-small {
0% {
-webkit-transform:translateY(0);
transform:translateY(0);
-webkit-animation-timing-function:cubic-bezier(1, 0, 0, 1);
animation-timing-function:cubic-bezier(1, 0, 0, 1)
}
100% {
-webkit-transform:translateY(100%);
transform:translateY(100%)
}
}
@-webkit-keyframes ac-gn-searchview-close-left-searchshow-small {
0% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg)
}
}
@keyframes ac-gn-searchview-close-left-searchshow-small {
0% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg)
}
}
@-webkit-keyframes ac-gn-searchview-close-left-searchhide-small {
0% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg)
}
}
@keyframes ac-gn-searchview-close-left-searchhide-small {
0% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg)
}
}
@-webkit-keyframes ac-gn-searchview-close-right-searchshow-small {
0% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg)
}
}
@keyframes ac-gn-searchview-close-right-searchshow-small {
0% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg)
}
}
@-webkit-keyframes ac-gn-searchview-close-right-searchhide-small {
0% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg)
}
}
@keyframes ac-gn-searchview-close-right-searchhide-small {
0% {
-webkit-transform:rotate(40deg);
transform:rotate(40deg);
-webkit-animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1);
animation-timing-function:cubic-bezier(0.645, 0.045, 0.355, 1)
}
100% {
-webkit-transform:rotate(-40deg);
transform:rotate(-40deg)
}
}


@-webkit-keyframes ac-gn-searchform-slide {
0% {
-webkit-transform:translate3d(100px, 0, 0);
transform:translate3d(100px, 0, 0);
-webkit-animation-timing-function:cubic-bezier(0.11393, 0.8644, 0.14684, 1);
animation-timing-function:cubic-bezier(0.11393, 0.8644, 0.14684, 1)
}
100% {
-webkit-transform:translateZ(0);
transform:translateZ(0)
}
}
@keyframes ac-gn-searchform-slide {
0% {



-webkit-transform:translate3d(100px, 0, 0);
transform:translate3d(100px, 0, 0);
-webkit-animation-timing-function:cubic-bezier(0.11393, 0.8644, 0.14684, 1);
animation-timing-function:cubic-bezier(0.11393, 0.8644, 0.14684, 1)
}
100% {
-webkit-transform:translateZ(0);
transform:translateZ(0)
}
}
@-webkit-keyframes ac-gn-searchform-fade {
0% {
opacity:0;
-webkit-animation-timing-function:cubic-bezier(0.67, 0, 0.33, 1);
animation-timing-function:cubic-bezier(0.67, 0, 0.33, 1)
}
100% {
opacity:1
}
}
@keyframes ac-gn-searchform-fade {
0% {
opacity:0;
-webkit-animation-timing-function:cubic-bezier(0.67, 0, 0.33, 1);
animation-timing-function:cubic-bezier(0.67, 0, 0.33, 1)
}
100% {
opacity:1
}
}
@-webkit-keyframes ac-gn-searchinput-fade {
0% {
opacity:0
}
100% {
opacity:1
}
}
@keyframes ac-gn-searchinput-fade {
0% {
opacity:0
}
100% {
opacity:1
}
}
#ac-globalnav input, #ac-globalnav button {
	border: none;
	background-color: transparent
}


@-webkit-keyframes ac-gn-searchresults-show {
0% {
opacity:0;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1
}
}
@keyframes ac-gn-searchresults-show {
0% {
opacity:0;
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1
}
}
@-webkit-keyframes ac-gn-searchresults-items-show {
0% {
opacity:0;
-webkit-transform:translateX(100px);
transform:translateX(100px);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1;
-webkit-transform:none;
transform:none
}
}
@keyframes ac-gn-searchresults-items-show {
0% {
opacity:0;
-webkit-transform:translateX(100px);
transform:translateX(100px);
-webkit-animation-timing-function:ease;
animation-timing-function:ease
}
100% {
opacity:1;
-webkit-transform:none;
transform:none
}
}

#ac-globalnav .ac-gn-searchresults-link b {
	color: #333;
	font-weight: 400
}

@media only screen and (max-width: 840px) {
	#ac-globalnav .ac-gn-searchresults-link b {


	color: #fff
}
}

html.ac-gn-segmentbar-visible body {
	border-top: 1px solid transparent;
	margin-top: -1px;
	position: relative
}

/*ony for one corner, use this variable several time for other corners*/
img {
  max-width: 100%;
}


.pi-img-w {
  margin-bottom: 0px;
  position: relative;
}
.pi-img-w .pi-img-link {
  display: block;
  overflow: hidden;
  padding: 0;
  border: 0;
}
.pi-img-w img {
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 100%;
}
.pi-grid-no-margins .pi-img-w {
  margin-bottom: 0;
}
.pi-gallery-small-margins .pi-img-w {
  margin-bottom: 20px;
}
.pi-img-left {
  float: left;
  margin-right: 25px;
}
.pi-img-right {
  float: right;
  margin-left: 25px;
}
.pi-img-center {

}

.pi-img-border-double .pi-img-overlay {
  top: 4px;
  left: 4px;
  bottom: 4px;
  right: 4px;
}


.pi-img-overlay {
  -webkit-backface-visibility: hidden;
  display: block;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  padding-top:15px;
  padding-bottom:30px;
  padding-left:20px;
  padding-right:20px;
  text-align: left;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.pi-img-w:hover .pi-img-overlay,
.pi-portfolio-description-box:hover .pi-img-overlay {
  opacity: 1;
}

.pi-img-overlay a {
  border-bottom: 0;
}

.pi-img-overlay .pi-caption-centered > *:first-child {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.pi-img-overlay .pi-caption-bottom > *:first-child {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.pi-img-with-overlay {
  overflow: hidden;
  position: relative;
}

.pi-overlay-slide h1,
.pi-overlay-slide h2,
.pi-overlay-slide h3,
.pi-overlay-slide h4,
.pi-overlay-slide h5,
.pi-overlay-slide h6 {
  margin-bottom: 19px;
}

.pi-caption-links li {
  display: inline-block;
  margin: 0 5px 0 0;
  color: rgba(250, 250, 250, 0.7);
}
.pi-caption-links li i {
  margin-right: 5px;
  color: rgba(250, 250, 250, 0.6);
}
.pi-caption-links li a {
  color: rgba(250, 250, 250, 0.7);
  -webkit-transition: color 0.1s ease-in-out 0s;
  -moz-transition: color 0.1s ease-in-out 0s;
  -ms-transition: color 0.1s ease-in-out 0s;
  -o-transition: color 0.1s ease-in-out 0s;
  transition: color 0.1s ease-in-out 0s;
}
.pi-caption-links li a i {
  margin-right: 5px;
  color: rgba(250, 250, 250, 0.6);
}
.pi-caption-links li a:hover {
  color: #fff;
}

.pi-img-opacity-30 img {
  opacity: 0.3;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -moz-transition: all 0.1s ease-in-out 0s;
  -ms-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
.pi-img-opacity-30:hover img {
  opacity: 1;
}
.pi-img-opacity-50 img {
  opacity: 0.5;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -moz-transition: all 0.1s ease-in-out 0s;
  -ms-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
.pi-img-opacity-50:hover img {
  opacity: 1;
}
.pi-img-opacity-70 img {
  opacity: 0.7;
  -webkit-transition: all 0.1s ease-in-out 0s;
  -moz-transition: all 0.1s ease-in-out 0s;
  -ms-transition: all 0.1s ease-in-out 0s;
  -o-transition: all 0.1s ease-in-out 0s;
  transition: all 0.1s ease-in-out 0s;
}
.pi-img-opacity-70:hover img {
  opacity: 1;
}
.pi-img-w:hover .pi-img-opacity-30 img,
.pi-img-w:hover .pi-img-opacity-50 img,
.pi-img-w:hover .pi-img-opacity-70 img {
  opacity: 1;
}
.pi-img-grayscale img {
  -webkit-filter: grayscale(100%);
}
.pi-img-grayscale-hover img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.pi-img-w:hover .pi-img-grayscale-hover img,
.pi-img-grayscale-hover:hover img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}
.pi-img-grayscale-hover-70 img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(70%);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.pi-img-w:hover .pi-img-grayscale-hover-70 img,
.pi-img-grayscale-hover-70:hover img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(0%);
}
/* ************************** */
.pi-section-base .pi-img-border {
  border-color: #619731;
}
.pi-section-base .pi-img-w:hover a .pi-img-border {
  border-color: #538b29;
}

.pi-section-white .pi-img-border {
  border-color: #e8eeee;
}
.pi-section-white .pi-img-w:hover a .pi-img-border {
  border-color: #609ddb;
}

.pi-section-grey .pi-img-border {
  border-color: #dbe3e3;
}
.pi-section-grey .pi-img-w:hover a .pi-img-border {
  border-color: #609ddb;
}

.pi-section-dark .pi-img-border {
  border-color: #2a3037;
}
.pi-section-dark .pi-img-w:hover a .pi-img-border {
  border-color: #609ddb;
}

.pi-img-overlay {
  background: rgba(0,51,102,.90);
  color: rgba(250, 250, 250, 0.8);
}
.pi-img-overlay h1,
.pi-img-overlay .h1,
.pi-img-overlay h2,
.pi-img-overlay .h2,
.pi-img-overlay h3,
.pi-img-overlay .h3,
.pi-img-overlay h4,
.pi-img-overlay .h4,
.pi-img-overlay h5,
.pi-img-overlay .h5,
.pi-img-overlay h6,
.pi-img-overlay .h6 {
  color: #fff !important;
}

.pi-img-overlay.pi-img-overlay-white {
  background: rgba(250, 250, 250, 0.2);
}

.pi-img-overlay.pi-img-overlay-whiter .pi-caption-links li {
  color: #4d5258;
}
.pi-img-overlay.pi-img-overlay-whiter .pi-caption-links li a {
  color: #4d5258;
}
.pi-img-overlay.pi-img-overlay-whiter .pi-caption-links li a:hover {
  color: #609ddb;
}

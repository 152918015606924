#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a,
#cssmenu #menu-button {

  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu:after,
#cssmenu > ul:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
#cssmenu #menu-button {
  display: none;
}
#cssmenu {
  width: auto;
  font-family: "myriad-pro",Arial,sans-serif!important;
  line-height: 1;
}
#cssmenu > ul {
}
#cssmenu > ul > li {
  float: left;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
}
#cssmenu.align-center > ul {
  font-size: 0;
  text-align: center;
}
#cssmenu.align-center > ul > li {
  display: inline-block;
  float: none;
}
#cssmenu.align-right > ul > li {
  float: right;
}
#cssmenu > ul > li > a {
  padding: 16px 20px;
  font-size: 13px!important;
  font-family: "myriad-pro",Arial,sans-serif!important;
  color: #333;
  text-decoration: none;
  background-color: #ffffff;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
#cssmenu > ul > li.active > a {
  color: #fff;
}
#cssmenu > ul > li:hover > a,
#cssmenu > ul > li > a:hover {
  color: #ddd;
  -webkit-transform: rotateX(90deg) translateY(-23px);
  -moz-transform: rotateX(90deg) translateY(-23px);
  transform: rotateX(90deg) translateY(-23px);
  -ms-transform: none;
}
#cssmenu > ul > li > a::before {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;


}
#cssmenu > ul > li:hover > a::before,
#cssmenu > ul > li > a:hover::before {
  background: #ffffff;
}
#cssmenu.small-screen {
  width: 100%;
}
#cssmenu.small-screen > ul,
#cssmenu.small-screen.align-center > ul {
  width: 100%;
  text-align: left;
}
#cssmenu.small-screen > ul > li,
#cssmenu.small-screen.align-center {
  float: none;
  display: block;
  border-top: 1px solid rgba(100, 100, 100, 0.1);
}
#cssmenu.small-screen > ul > li:hover > a,
#cssmenu.small-screen > ul > li > a:hover {
  color: #08c;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  -ms-transform: none;
}
#cssmenu.small-screen > ul > li > a::before {
  display: none;
}
#cssmenu.small-screen #menu-button {
  display: block;
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 28px;
  font-weight: 200;
  color: #333;
  background-color: rgba(255,255,255,.85);
}
 #cssmenu.small-screen #menu-button:after {
  content: "";
  position: absolute;
  right: 20px;
  top: 20px;
  display: block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
    border-top: 1px solid #08c;
    border-bottom: 1px solid #08c;
  width: 19px;
  height: 6px;
}

#cssmenu.small-screen #menu-button:before {
  content: "";
  position: absolute;
  right: 20px;
  top: 27px;
  display: block;
  width: 19px;
  height: 1px;

}

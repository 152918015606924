/*ony for one corner, use this variable several time for other corners*/
.isotope {
  position: relative;
}
.pi-gallery {
  margin-left: -15px;
  margin-right: -15px;
}
.pi-gallery:before,
.pi-gallery:after {
  content: " ";
  /* 1 */

  display: table;
  /* 2 */

}
.pi-gallery:after {
  clear: both;
}
.pi-gallery .pi-gallery-item {
  padding-left: 15px;
  padding-right: 15px;
}

.pi-gallery.pi-gallery-small-margins .pi-gallery-item {
  padding-left: 10px;
  padding-right: 10px;
}

.pi-gallery.pi-gallery-big-margins .pi-gallery-item {
  padding-left: 32px;
  padding-right: 32px;
}
.pi-gallery .pi-gallery-item img {
  width: 100%;
}

.pi-gallery.pi-stacked .pi-gallery-item,
.pi-gallery.pi-stacked .pi-gallery-item .pi-img-w {
  margin: 0 !important;
}
.pi-gallery.pi-stacked .pi-gallery-item {
  padding: 0 !important;
}
@media (min-width: 0px) {
  .pi-gallery.pi-liquid-col-3xs-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-6 .pi-gallery-item {
    float: left;
  }
  .pi-gallery.pi-liquid-col-3xs-1 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-6 .pi-gallery-item {
    padding: 0 15px;
  }

  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-6 .pi-gallery-item {
    padding: 0 10px;
  }

  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-6 .pi-gallery-item {
    padding: 0 32px;
  }
  .pi-liquid-col-3xs-6 .pi-gallery-item {
    width: 16.6%;
  }
  .pi-liquid-col-3xs-5 .pi-gallery-item {
    width: 20%;
  }
  .pi-liquid-col-3xs-4 .pi-gallery-item {
    width: 25%;
  }
  .pi-liquid-col-3xs-3 .pi-gallery-item {
    width: 33.3%;
  }
  .pi-liquid-col-3xs-2 .pi-gallery-item {
    width: 50%;
  }
  .pi-liquid-col-3xs-1 .pi-gallery-item {
    width: 100%;
  }
}
@media (min-width: 320px) {
  .pi-gallery.pi-liquid-col-2xs-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-6 .pi-gallery-item {
    float: left;
  }
  .pi-gallery.pi-liquid-col-2xs-1 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-2xs-6 .pi-gallery-item {
    padding: 0 15px;
  }

  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-2xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-2xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-2xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-2xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-2xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-2xs-6 .pi-gallery-item {
    padding: 0 10px;
  }

  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-2xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-2xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-2xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-2xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-2xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-2xs-6 .pi-gallery-item {
    padding: 0 32px;
  }
  .pi-liquid-col-2xs-6 .pi-gallery-item {
    width: 16.6%;
  }
  .pi-liquid-col-2xs-5 .pi-gallery-item {
    width: 20%;
  }
  .pi-liquid-col-2xs-4 .pi-gallery-item {
    width: 25%;
  }
  .pi-liquid-col-2xs-3 .pi-gallery-item {
    width: 33.3%;
  }
  .pi-liquid-col-2xs-2 .pi-gallery-item {
    width: 50%;
  }
  .pi-liquid-col-2xs-1 .pi-gallery-item {
    width: 100%;
  }
}
@media (min-width: 80px) {
  .pi-gallery.pi-liquid-col-xs-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-6 .pi-gallery-item {
    float: left;
  }
}
@media (min-width: 480px) {
.pi-gallery.pi-liquid-col-xs-2 .pi-gallery-item,
.pi-gallery.pi-liquid-col-xs-3 .pi-gallery-item,
.pi-gallery.pi-liquid-col-xs-4 .pi-gallery-item,
.pi-gallery.pi-liquid-col-xs-5 .pi-gallery-item,
.pi-gallery.pi-liquid-col-xs-6 .pi-gallery-item {
  float: left;
}
.pi-col-xs-4 {
  width: 33.33333333333333%;
}

  .pi-col-xs-1, .pi-col-xs-2, .pi-col-xs-3, .pi-col-xs-4, .pi-col-xs-5, .pi-col-xs-5-cell, .pi-col-xs-6, .pi-col-xs-7, .pi-col-xs-8, .pi-col-xs-9, .pi-col-xs-10, .pi-col-xs-11 {
    float: left;
}
  .pi-gallery.pi-liquid-col-xs-1 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-xs-6 .pi-gallery-item {
    padding: 0 15px;
  }
  .pi-gallery.pi-gallery-small-margins {
    margin-left: -10px;
    margin-right: -10px;
  }
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-xs-6 .pi-gallery-item {
    padding: 0 10px;
  }

  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-xs-6 .pi-gallery-item {
    padding: 0 32px;
  }
  .pi-liquid-col-xs-6 .pi-gallery-item {
    width: 16.6%;
  }
  .pi-liquid-col-xs-5 .pi-gallery-item {
    width: 50%;
  }
  .pi-liquid-col-xs-4 .pi-gallery-item {
    width: 25%;
  }
  .pi-liquid-col-xs-3 .pi-gallery-item {
    width: 33.3%;
  }
  .pi-liquid-col-xs-2 .pi-gallery-item {
    width: 50%;
  }
  .pi-liquid-col-xs-1 .pi-gallery-item {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .pi-gallery.pi-liquid-col-sm-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-6 .pi-gallery-item {
    float: left;
  }
  .pi-gallery.pi-liquid-col-sm-1 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-sm-6 .pi-gallery-item {
    padding: 0 15px;
  }
  .pi-gallery.pi-gallery-small-margins {
    margin-left: -10px;
    margin-right: -10px;
  }
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-sm-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-sm-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-sm-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-sm-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-sm-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-sm-6 .pi-gallery-item {
    padding: 0 10px;
  }
  .pi-gallery.pi-gallery-big-margins {
    margin-left: -32px;
    margin-right: -32px;
  }
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-sm-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-sm-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-sm-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-sm-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-sm-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-sm-6 .pi-gallery-item {
    padding: 0 32px;
  }
  .pi-liquid-col-sm-6 .pi-gallery-item {
    width: 16.6%;
  }
  .pi-liquid-col-sm-5 .pi-gallery-item {
    width: 20%;
  }
  .pi-liquid-col-sm-4 .pi-gallery-item {
    width: 25%;
  }
  .pi-liquid-col-sm-3 .pi-gallery-item {
    width: 33.3%;
  }
  .pi-liquid-col-sm-2 .pi-gallery-item {
    width: 50%;
  }
  .pi-liquid-col-sm-1 .pi-gallery-item {
    width: 100%;
  }

  .pi-liquid-col-xs-5 .pi-gallery-item {
    width: 20%;
  }
}
@media (min-width: 992px) {
  .pi-gallery.pi-liquid-col-md-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-6 .pi-gallery-item {
    float: left;
  }
  .pi-gallery.pi-liquid-col-md-1 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-md-6 .pi-gallery-item {
    padding: 0 15px;
  }
  .pi-gallery.pi-gallery-small-margins {
    margin-left: -10px;
    margin-right: -10px;
  }
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-md-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-md-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-md-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-md-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-md-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-md-6 .pi-gallery-item {
    padding: 0 10px;
  }
  .pi-gallery.pi-gallery-big-margins {
    margin-left: -32px;
    margin-right: -32px;
  }
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-md-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-md-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-md-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-md-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-md-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-md-6 .pi-gallery-item {
    padding: 0 32px;
  }
  .pi-liquid-col-md-6 .pi-gallery-item {
    width: 16.6%;
  }
  .pi-liquid-col-md-5 .pi-gallery-item {
    width: 20%;
  }
  .pi-liquid-col-md-4 .pi-gallery-item {
    width: 25%;
  }
  .pi-liquid-col-md-3 .pi-gallery-item {
    width: 33.3%;
  }
  .pi-liquid-col-md-2 .pi-gallery-item {
    width: 50%;
  }
  .pi-liquid-col-md-1 .pi-gallery-item {
    width: 100%;
  }
}
@media (min-width: 1120px) {
  .pi-gallery.pi-liquid-col-lg-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-6 .pi-gallery-item {
    float: left;
  }
  .pi-gallery.pi-liquid-col-lg-1 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-lg-6 .pi-gallery-item {
    padding: 0 15px;
  }
  .pi-gallery.pi-gallery-small-margins {
    margin-left: -10px;
    margin-right: -10px;
  }
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-lg-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-lg-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-lg-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-lg-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-lg-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-lg-6 .pi-gallery-item {
    padding: 0 10px;
  }
  .pi-gallery.pi-gallery-big-margins {
    margin-left: -32px;
    margin-right: -32px;
  }
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-lg-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-lg-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-lg-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-lg-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-lg-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-lg-6 .pi-gallery-item {
    padding: 0 32px;
  }
  .pi-liquid-col-lg-6 .pi-gallery-item {
    width: 16.6%;
  }
  .pi-liquid-col-lg-5 .pi-gallery-item {
    width: 20%;
  }
  .pi-liquid-col-lg-4 .pi-gallery-item {
    width: 25%;
  }
  .pi-liquid-col-lg-3 .pi-gallery-item {
    width: 33.3%;
  }
  .pi-liquid-col-lg-2 .pi-gallery-item {
    width: 50%;
  }
  .pi-liquid-col-lg-1 .pi-gallery-item {
    width: 100%;
  }
}
@media (max-width: 319px) {
  .pi-gallery {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .pi-gallery.pi-liquid-col-3xs-1 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-2 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-3 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-4 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-5 .pi-gallery-item,
  .pi-gallery.pi-liquid-col-3xs-6 .pi-gallery-item {
    padding: 0 7.5px;
  }
  .pi-gallery.pi-gallery-small-margins {
    margin-left: -5px;
    margin-right: -5px;
  }
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-small-margins.pi-liquid-col-3xs-6 .pi-gallery-item {
    padding: 0 5px;
  }
  .pi-gallery.pi-gallery-big-margins {
    margin-left: -16px;
    margin-right: -16px;
  }
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-1 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-2 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-3 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-4 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-5 .pi-gallery-item,
  .pi-gallery.pi-gallery-big-margins.pi-liquid-col-3xs-6 .pi-gallery-item {
    padding: 0 16px;
  }
}

/*ony for one corner, use this variable several time for other corners*/
.pi-alert-note h1,
.pi-alert-warning h1,
.pi-alert-danger h1,
.pi-alert-success h1,
.pi-alert-info h1,
.pi-alert-note h2,
.pi-alert-warning h2,
.pi-alert-danger h2,
.pi-alert-success h2,
.pi-alert-info h2,
.pi-alert-note h3,
.pi-alert-warning h3,
.pi-alert-danger h3,
.pi-alert-success h3,
.pi-alert-info h3,
.pi-alert-note h4,
.pi-alert-warning h4,
.pi-alert-danger h4,
.pi-alert-success h4,
.pi-alert-info h4,
.pi-alert-note h5,
.pi-alert-warning h5,
.pi-alert-danger h5,
.pi-alert-success h5,
.pi-alert-info h5,
.pi-alert-note h6,
.pi-alert-warning h6,
.pi-alert-danger h6,
.pi-alert-success h6,
.pi-alert-info h6 {
  margin-bottom: 7px;
  font-size: 14px !important;
  font-weight: 600;
}

.pi-alert-note h1,
.pi-alert-note h2,
.pi-alert-note h3,
.pi-alert-note h4,
.pi-alert-note h5,
.pi-alert-note h6,
.pi-alert-note strong {
  color: #212326 !important;
}

.pi-alert-success h1,
.pi-alert-success h2,
.pi-alert-success h3,
.pi-alert-success h4,
.pi-alert-success h5,
.pi-alert-success h6,
.pi-alert-success strong {
  color: #005A28 !important;
}
.pi-alert-success a,
.pi-alert-success a:hover {
  color: #005A28;
}

.pi-alert-info h1,
.pi-alert-info h2,
.pi-alert-info h3,
.pi-alert-info h4,
.pi-alert-info h5,
.pi-alert-info h6,
.pi-alert-info strong {
  color: #154B7A !important;
}
.pi-alert-info a,
.pi-alert-info a:hover {
  color: #154B7A;
}

.pi-alert-danger h1,
.pi-alert-danger h2,
.pi-alert-danger h3,
.pi-alert-danger h4,
.pi-alert-danger h5,
.pi-alert-danger h6,
.pi-alert-danger strong {
  color: #ae2128 !important;
}
.pi-alert-danger a,
.pi-alert-danger a:hover {
  color: #ae2128;
}

.pi-alert-warning h1,
.pi-alert-warning h2,
.pi-alert-warning h3,
.pi-alert-warning h4,
.pi-alert-warning h5,
.pi-alert-warning h6,
.pi-alert-warning strong {
  color: #68422C !important;
}
.pi-alert-warning a,
.pi-alert-warning a:hover {
  color: #68422C;
}
